<template lang="pug">
    app-view-card(:title="$t('pricelists.panel_title')")
        div(v-for="(priceListEntry, key) in companyPriceListEntries")
            app-view-card(:title="$t(`pricelists.${priceListEntry.productType.code}`)")
                .columns.is-multiline
                    .column.max-table-width-medium.is-full-tablet
                        app-view-card(:title="$t(`pricelists.margins`)")
                            template(#icon)
                                div(v-if="shouldShowHint(priceListEntry)" @mousedown="displayMargins = true" @mouseup="displayMargins = false")
                                    b-icon(icon="eye")
                            .notification.is-info(v-if="displayMargins")
                              p(v-if="priceListEntry.percentMargin > 0")
                                | {{ $t('pricelists.percent_margin_label') }}: {{ formatPrice(priceListEntry.percentMargin) }}
                              p(v-if="priceListEntry.amountMargin > 0")
                                | {{ $t('pricelists.amount_margin_label') }}: {{ formatPrice(priceListEntry.amountMargin) }}
                              p(v-if="priceListEntry.percentDiscount > 0")
                                | {{ $t('pricelists.percent_discount_label') }}: {{ formatPrice(priceListEntry.percentDiscount) }}
                              p(v-if="priceListEntry.amountDiscount > 0")
                                | {{ $t('pricelists.amount_discount_label') }}: {{ formatPrice(priceListEntry.amountDiscount) }}
                            app-form(:model="priceListEntryModel" :object="priceListEntry" resource="company_price_list_entries" :formDataShouldBeObject="true" :transform-form-data-before-save="transformToSubmit")
                                template(#default="{ data, errors }")
                                    div(v-if="data.type === 'own'")
                                        .notification.is-info
                                            span {{ $t("pricelists.info") }}
                                        app-form-field(:label="$t('pricelists.percent_margin')" field="percentMargin" :errors="errors")
                                            b-field
                                                app-form-decimal(v-model="data.percentMargin")
                                                p(class="control")
                                                    span(class="button is-static") %

                                        app-form-field(:label="$t('pricelists.amount_margin')" field="amountMargin" :errors="errors")
                                            b-field
                                                app-form-decimal(v-model="data.amountMargin")
                                                p(class="control")
                                                    span(class="button is-static") {{ currencyCode }}
                                        br

                                    b-field(:label="$t('pricelists.type')")
                                        b-field
                                            b-radio-button(v-model="data.type" native-value="catalog" type="is-info")
                                                span {{ $t('pricelists.catalog') }}
                                            b-radio-button(v-model="data.type" native-value="own" type="is-danger")
                                                span {{ $t('pricelists.own') }}
                    .divider.is-vertical.is-right.is-hidden-touch
            .divider.is-right(v-if="key < companyPriceListEntries.length - 1")
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import http from "../../../http";
import collectionToObject from "../../../helpers/collectionToObject";

export default {
  data() {
    return {
      displayMargins: false,
      priceListEntryModel: {
        id: "",
        amountDiscount: "",
        amountMargin: "",
        percentDiscount: "",
        percentMargin: "",
        productType: "",
        type: "",
        wholesalePriceList: {}
      }
    };
  },
  computed: {
    ...mapGetters("common", ["formatPrice"]),
    ...mapGetters("company", [
      "companyId",
      "companyPriceListEntries",
      "currencyCode"
    ])
  },
  async mounted() {
      const companyId = this.$store.state.auth.companyId;
      const companyPriceListResponse = await http.get(`/companies/${companyId}/company_price_list_entries`);

      const companyPriceLists = companyPriceListResponse.data["hydra:member"];

      this.setCompanyPriceListEntries(
          collectionToObject(companyPriceLists)
      );
  },
  methods: {
    ...mapMutations("company", ["setCompanyPriceListEntries"]),
    shouldShowHint(priceListEntry) {
      return (
          priceListEntry.percentMargin > 0 ||
          priceListEntry.amountMargin > 0 ||
          priceListEntry.percentDiscount > 0 ||
          priceListEntry.amountDiscount > 0
      );
    },
    transformToSubmit(data) {
      if (data.productType && data.productType["@id"]) {
        data.productType = data.productType["@id"];
      }

      if (data.wholesalePriceList && data.wholesalePriceList["@id"]) {
        data.wholesalePriceList = data.wholesalePriceList["@id"];
      }

      return data;
    }
  }
};
</script>
