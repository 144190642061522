import http from "../../http";
import simpleClone from "../../helpers/simpleClone";
import generateProperties from "@/helpers/generateProperties";
import notify from "@/helpers/notify";
import Vue from "vue";
import t from "@/i18n";
import router from "@/router";
import prepareOrderToSent from "@/helpers/prepareOrderToSent";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import objectToCollection from "@/helpers/objectToCollection";
import prepareItemToSent from "../../helpers/prepareItemToSent";
import store from "../../store";

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this,
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

let defaultChangeStatusesForItemWhileEdit = {
    accessory: false,
    balk: false,
    group: false,
    fabricColorDayNight: false,
    fabricDayNight: false,
    runner: false
};

let defaultItem = {
    quantity: 1,
    fabricWidth: 0,
    width: 0,
    glassWidth: 0,
    height: 0,
    glassHeight: 0,
    driveSide: "P",
    fabricColorDayNight: null,
    fabricDayNight: null,
    fabricShadeDayNight: null,
    fabricShadeDayNightGlobalRunnerEntry: null,
    fabricShadeDayNightGlobalFixingEntry: null,
    fabricShadeDayNightGlobalAccessoryEntry: null,
    fabricShadeDayNightGlobalBalkEntry: null,
    fabricShadeDayNightFabricShadeDayNightAdditionalEntries: [],
    fabricShadeDayNightGroup: null,
    globalPipe: null,
    globalDrive: null,
    globalDriveControl: null,
    globalRemoteControl: null,
    hasTurnableFabric: false,
    netPurchaseValue: 0,
    netSalesValue: 0,
    globalRemoteControlQuantity: 0,
    companyInstallationValue: null,
    shortDescription: null,
    hasOversize: false,
    hasConfirmedOversize: false
};
let defaultOrder = {
    name: "",
    category: "",
    fabricShadeDayNightOrderItems: [],
    automationOrderItems: [],
    customer: {
        type: "person",
        companyName: null,
        firstName: null,
        lastName: null,
        zipCode: null,
        city: null,
        streetAndNumber: null,
        phone: null,
        email: null,
        taxId: null
    },
    netSalesValue: 0,
    salesGrossValue: 0,
    totalSalesNetValue: 0,
    leftToPayValue: 0,
    netSalesValueAfterDiscount: 0,
    percentDiscount: 0,
    withInstallation: false,
    installationValue: 0,
    installationValuePerItem: 0,
    fitterTravelCost: null,
    fitterTravelCostValue: 0,
    advanceValue: 0,
    vatRate: null,
    vatRateValue: 0,
    companyVatRate: null,
    salesVatRateValue: 0,
    paymentMethod: null,
    deliveryMethod: null,
    discountValue: 0,
    packageNote: "",
    notes: ""
};
export default {
    namespaced: true,
    state: {
        extraPackageNote: "",
        showForm: true,
        errors: [],
        loading: false,
        fabricShadeDayNights: [],
        fabricShadeDayNightGlobalAccessoryEntries: [],
        fabricShadeDayNightGlobalBalkEntries: [],
        fabricShadeDayNightGlobalRunnerEntries: [],
        fabricShadeDayNightGlobalFixingEntries: [],
        fabricShadeDayNightFabricShadeDayNightAdditionalEntries: [],
        fabricShadeDayNightGroups: [],
        traitFabric: null,
        traitColor: null,
        fabricShadeColor: null,
        item: simpleClone(defaultItem),
        order: simpleClone(defaultOrder),
        editIndex: null,
        gusFetching: false,
        driveTypeFilter: null,
        driveSeriesFilter: null,
        message: null,
        isMessageBorderAnimating: false,
        changeStatusesForEditItem: simpleClone(
            defaultChangeStatusesForItemWhileEdit
        )
    },
    getters: {
        extraPackageNote: state => state.extraPackageNote,
        orderNumber: state => {
            let number = "";
            if (state.order && state.order.id) {
                for (let i = state.order.id.toString().length; i < 6; i++) {
                    number += "0";
                }
                number += state.order.id.toString();
            }
            return number;
        },
        customErrors: state => state.customErrors,
        showForm: state => state.showForm,
        errors: state => state.errors,
        changeStatusesForEditItem: state => state.changeStatusesForEditItem,
        isMessageBorderAnimating: state => state.isMessageBorderAnimating,
        message: state => state.message,
        driveTypeFilter: state => state.driveTypeFilter,
        driveSeriesFilter: state => state.driveSeriesFilter,
        isWithGlassSizes: state => {
            return state.item.fabricShadeDayNight && state.item.fabricShadeDayNight["isWithGlassSizes"] ? state.item.fabricShadeDayNight["isWithGlassSizes"] : false
        },
        itemDriveTypes: (state, getters) => {
            if (!getters.item || !getters.item.fabricShadeDayNight || !getters.item.width) {
                return [];
            }

            const pipes = getters.item.fabricShadeDayNight.globalPipes;
            if (!pipes || pipes.length === 0) {
                return [];
            }

            let distinctDrivesIndexes = [];
            let drives = [];
            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    if (
                        !distinctDrivesIndexes.includes(drive.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        drives.push(drive);
                        distinctDrivesIndexes.push(drive.id);
                    }
                });
            });

            let distinctIndexes = [];
            let filtered = [];

            drives.forEach(drive => {
                const type = drive.globalDriveType;
                if (type && !distinctIndexes.includes(type.id)) {
                    distinctIndexes.push(type.id);
                    filtered.push(type);
                }
            });
            return filtered;
        },
        itemDriveSeries: (state, getters) => {
            if (!getters.driveTypeFilter) {
                return [];
            }

            if (!getters.item || !getters.item.fabricShadeDayNight || !getters.item.width) {
                return [];
            }

            const pipes = getters.item.fabricShadeDayNight.globalPipes;
            if (!pipes || pipes.length === 0) {
                return [];
            }

            let distinctSeriesIndexes = [];
            let series = [];
            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    const seriesObj = drive.globalDriveSeries;
                    const type = drive.globalDriveType;
                    if (
                        seriesObj &&
                        type &&
                        type["@id"] === getters.driveTypeFilter["@id"] &&
                        !distinctSeriesIndexes.includes(seriesObj.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        series.push(seriesObj);
                        distinctSeriesIndexes.push(seriesObj.id);
                    }
                });
            });
            return series;
        },
        itemGlobalDrives: (state, getters) => {
            if (
                !getters.driveTypeFilter ||
                !getters.driveSeriesFilter ||
                !getters.item ||
                !getters.item.fabricShadeDayNight ||
                !getters.item.width
            ) {
                return [];
            }

            const pipes = getters.item.fabricShadeDayNight.globalPipes;
            if (!pipes) {
                return [];
            }

            let distinctDrivesIndexes = [];
            let drives = [];

            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    const series = drive.globalDriveSeries;
                    const type = drive.globalDriveType;
                    if (
                        series &&
                        type &&
                        series["@id"] === getters.driveSeriesFilter["@id"] &&
                        type["@id"] === getters.driveTypeFilter["@id"] &&
                        !distinctDrivesIndexes.includes(drive.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        drives.push(drive);
                        distinctDrivesIndexes.push(drive.id);
                    }
                });
            });

            return drives;
        },
        itemGlobalDriveControls: (state) => () => {
            return state.item.globalDrive ? state.item.globalDrive["globalDriveControls"] : []
        },
        byFabricShadeColor: (state, getters) => fabricShadeColor =>
            fabricShadeColor["@id"] === getters.fabricShadeColor,
        itemFabricShadeDayNightGlobalAccessoryEntries: (state, getters) => {
            const fabricShadeDayNight = state.item.fabricShadeDayNight;
            if (!fabricShadeDayNight) {
                return [];
            }
            if (!fabricShadeDayNight.fabricShadeDayNightGlobalAccessoryEntries) {
                return [];
            }
            return fabricShadeDayNight.fabricShadeDayNightGlobalAccessoryEntries.filter(entry => {
                // jeśli jest filtr to wyklucz ten osprzęt, który nie ma tego koloru
                return !(
                    getters.fabricShadeColor &&
                    entry.globalAccessory.fabricShadeColors.findIndex(
                        getters.byFabricShadeColor
                    ) < 0
                );
            });
        },
        itemFabricShadeDayNightGlobalBalkEntries: (state, getters) => {
            const fabricShadeDayNight = state.item.fabricShadeDayNight;
            if (!fabricShadeDayNight) {
                return [];
            }
            if (!fabricShadeDayNight.fabricShadeDayNightGlobalBalkEntries) {
                return [];
            }
            return fabricShadeDayNight.fabricShadeDayNightGlobalBalkEntries.filter(entry => {
                // jeśli jest filtr to wyklucz te belki, które nie mają tego koloru
                return !(
                    getters.fabricShadeColor &&
                    entry.globalBalk.fabricShadeColors.findIndex(
                        getters.byFabricShadeColor
                    ) < 0
                );
            });
        },
        itemFabricShadeDayNightGlobalRunnerEntries: (state, getters) => {
            const fabricShadeDayNight = state.item.fabricShadeDayNight;
            if (!fabricShadeDayNight) {
                return [];
            }
            if (!fabricShadeDayNight.fabricShadeDayNightGlobalRunnerEntries) {
                return [];
            }

            return fabricShadeDayNight.fabricShadeDayNightGlobalRunnerEntries.filter(entry => {
                // jeśli jest filtr to wyklucz te prowadnice, które nie mają tego koloru
                return !(
                    getters.fabricShadeColor &&
                    entry.globalRunner.fabricShadeColors.findIndex(
                        getters.byFabricShadeColor
                    ) < 0
                );
            });
        },
        itemFabricColors: (state, getters) => {
            const fabric = state.item.fabricDayNight;
            if (!fabric) {
                return [];
            }
            return fabric.fabricColorDayNights.filter(fabricColorDayNight => {
                // jeśli jest filtr to wyklucz te kolory tkanin, które nie mają cechy z filtru
                if (getters.traitColor) {
                    if (
                        fabricColorDayNight.traitColors.findIndex(
                            traitColor => traitColor["@id"] === getters.traitColor
                        ) < 0
                    ) {
                        return false;
                    }
                }
                return true;
            });
        },
        itemFabrics: (state, getters) => {
            const fabricShadeDayNightGroup = state.item.fabricShadeDayNightGroup;
            if (!fabricShadeDayNightGroup) {
                return [];
            }
            let fabricDayNights = fabricShadeDayNightGroup.fabricDayNights.filter(fabric => {
                // jeśli wybrano ceche tkaniny wyklucz tkaninę, która nie ma takiej
                return !(getters.traitFabric &&
                    fabric.traitFabrics.findIndex(
                        trait => trait["@id"] === getters.traitFabric
                    ) < 0);
            });
            //jesli wybrano filtr koloru tkaniny wyswietl tylko te kolekcje, których tkaniny posiadają ten kolor
            if (getters.traitColor) {
                return fabricDayNights.filter(fabric => {
                    return (
                        0 <
                        fabric.fabricColorDayNights.filter(shadeColor => {
                            return (
                                0 <
                                shadeColor.traitColors.filter(
                                    color => color["@id"] === getters.traitColor
                                ).length
                            );
                        }).length
                    );
                });
            }
            return fabricDayNights;
        },
        fabricShadeColor: state => state.fabricShadeColor,
        traitColor: state => state.traitColor,
        traitFabric: state => state.traitFabric,
        isEdit: (state, getters) => !!getters.item.id,
        item: state => state.item,
        editIndex: state => state.editIndex,
        order: state => state.order,
        loading: state => state.loading,
        gusFetching: state => state.gusFetching,
        fabricShadeDayNights: state => state.fabricShadeDayNights,
        fabricShadeDayNightGlobalAccessoryEntries: state => {
            if (!state.fabricShadeDayNightGlobalAccessoryEntries) {
                return [];
            }
            return state.fabricShadeDayNightGlobalAccessoryEntries.filter(entry => {
                return !(
                    state.fabricShadeColor !== null &&
                    0 ===
                    entry.globalAccessory.fabricShadeColors.filter(
                        color => color["@id"] === state.fabricShadeColor
                    ).length
                );
            });
        },
        fabricShadeDayNightGlobalBalkEntries: state => {
            if (!state.fabricShadeDayNightGlobalBalkEntries) {
                return [];
            }
            return state.fabricShadeDayNightGlobalBalkEntries;
        },
        fabricShadeDayNightGlobalRunnerEntries: state =>
            state.fabricShadeDayNightGlobalRunnerEntries,
        fabricShadeDayNightGroups: state => state.fabricShadeDayNightGroups,
        traits: state => ({
            traitColor: state.traitColor,
            traitFabric: state.traitFabric
        }),
        // unikalne cechy kolorów z kolorów tkanin z tkanin grupy
        fabricShadeDayNightGroupTraitColorsSet: () => group => {
            let colors = new Set();
            group.fabricDayNights.forEach(fabric => {
                fabric.fabricColorDayNights.forEach(fabricColorDayNight => {
                    fabricColorDayNight.traitColors.forEach(traitColor => {
                        colors.add(traitColor["@id"]);
                    });
                });
            });
            return colors;
        },
        // filtrowane grupy do selecta
        itemFabricShadeDayNightGroups: (state, getters) => {
            let filteredGroups = state.fabricShadeDayNightGroups.filter(group => {
                // jeśli wybrano kolor tkaniny wyklucz grupę, której
                // tkaniny nie mają w ich kolorach takiej cechy koloru
                if (
                    getters.traitColor !== null &&
                    !getters.fabricShadeDayNightGroupTraitColorsSet(group).has(getters.traitColor)
                ) {
                    return false;
                }
                // jeśli wybrano cechę tkaniny to wyklucz tą grupę, która nie ma tkanin z takim zaciemnieniem
                if (
                    getters.traitFabric !== null &&
                    group.fabricDayNights.filter(
                        fabric =>
                            fabric.traitFabrics.findIndex(
                                trait => trait["@id"] === getters.traitFabric
                            ) >= 0
                    ).length <= 0
                ) {
                    return false;
                }
                // w pozostałych przypadkach dołącz grupę
                return true;
            });

            // podmienia nazwe grupy na liste symboli kolekcji tkanin
            return filteredGroups.map(group => {
                let name = group.position + ". ";
                let counter = 1;
                group.fabricDayNights.forEach(fabric => {
                    name += fabric.symbol;
                    if (counter !== group.fabricDayNights.length) {
                        name += ", ";
                    }
                    counter += 1;
                });
                group.name = name;

                return group;
            });
        },
        fabricShadeDayNightGlobalFixingEntries: state =>
            state.fabricShadeDayNightGlobalFixingEntries,
        image: () => (object, imageName, extraField = null) => {
            if (extraField) {
                const field = object && object[extraField];
                if (field) {
                    const picture = field[imageName];
                    if (picture) {
                        return picture.contentUrl;
                    }
                    return null;
                }
                return null;
            }
            const picture = object && object[imageName];
            if (picture) {
                return picture.contentUrl;
            }
            return null;
        },
        fabricShadeDayNightFabricShadeDayNightAdditionalEntries: state => state.fabricShadeDayNightFabricShadeDayNightAdditionalEntries,
        itemGlobalRemoteControls: (state, getters) => {
            const drive = getters.item.globalDrive;
            const control = getters.item.globalDriveControl;
            let remotes = [];

            if (drive && drive.globalRemoteControls) {
                remotes = remotes.concat(drive.globalRemoteControls);
            }
            if (control && control.globalRemoteControls) {
                remotes = remotes.concat(control.globalRemoteControls);
            }

            let distinctIndexes = [];
            let filtered = [];

            for (let i = 0; i < remotes.length; i++) {
                if (!distinctIndexes.includes(remotes[i].id)) {
                    distinctIndexes.push(remotes[i].id);
                }
            }

            remotes.forEach(remote => {
                if (distinctIndexes.includes(remote.id)) {
                    filtered.push(remote);
                    const index = distinctIndexes.indexOf(remote.id);
                    distinctIndexes.splice(index, 1);
                    return true;
                }
                return false;
            });

            return filtered;
        }
    },
    mutations: {
        setExtraPackageNote(state, payload) {
            state.extraPackageNote = payload;
        },
        toggleShowForm(state) {
            state.showForm = !state.showForm;
        },
        addError(state, payload) {
            state.errors.push({
                propertyPath: payload.field,
                message: payload.message
            });
        },
        deleteError(state, name) {
            const index = state.errors.findIndex(
                error => error.propertyPath === name
            );
            if (index > -1) {
                Vue.delete(state.errors, index);
            }
        },
        setIsMessageBorderAnimating(state, payload) {
            state.isMessageBorderAnimating = payload;
        },
        setMessage(state, payload) {
            state.message = payload;
        },
        setDriveTypeFilter(state, payload) {
            state.driveTypeFilter = payload;
        },
        setDriveSeriesFilter(state, payload) {
            state.driveSeriesFilter = payload;
        },
        resetFabricShadeColor(state) {
            state.fabricShadeColor = null;
        },
        resetTraitColor(state) {
            state.traitColor = null;
        },
        resetTraitFabric(state) {
            state.traitFabric = null;
        },
        clearFilters(state) {
            state.traitColor = null;
            state.traitFabric = null;
            state.fabricShadeColor = null;
        },
        setTraitColor(state, payload) {
            state.traitColor = payload;
        },
        setFabricShadeColor(state, payload) {
            state.fabricShadeColor = payload;
        },
        setTraitFabric(state, payload) {
            state.traitFabric = payload;
        },
        startLoading(state) {
            state.loading = true;
        },
        endLoading(state) {
            state.loading = false;
        },
        startGusFetching(state) {
            state.gusFetching = true;
        },
        endGusFetching(state) {
            state.gusFetching = false;
        },
        setFabricShadeDayNightOrderItems(state, fabricShadeDayNightOrderItems) {
            state.order.fabricShadeDayNightOrderItems = fabricShadeDayNightOrderItems;
        },
        setFabricShadeDayNights(state, fabricShadeDayNights) {
            state.fabricShadeDayNights = fabricShadeDayNights;
        },
        setFabricShadeDayNightGlobalAccessoryEntries(
            state,
            fabricShadeDayNightGlobalAccessoryEntries
        ) {
            state.fabricShadeDayNightGlobalAccessoryEntries = fabricShadeDayNightGlobalAccessoryEntries;
        },
        setFabricShadeDayNightGlobalBalkEntries(state, fabricShadeDayNightGlobalBalkEntries) {
            state.fabricShadeDayNightGlobalBalkEntries = fabricShadeDayNightGlobalBalkEntries;
        },
        setFabricShadeDayNightGlobalRunnerEntries(state, fabricShadeDayNightGlobalRunnerEntries) {
            state.fabricShadeDayNightGlobalRunnerEntries = fabricShadeDayNightGlobalRunnerEntries;
        },
        setFabricShadeDayNightGlobalFixingEntries(state, fabricShadeDayNightGlobalFixingsEntries) {
            state.fabricShadeDayNightGlobalFixingEntries = fabricShadeDayNightGlobalFixingsEntries;
        },
        setFabricShadeDayNightGroups(state, fabricShadeDayNightGroups) {
            state.fabricShadeDayNightGroups = fabricShadeDayNightGroups;
        },
        setOrder(state, order) {
            state.order = order;
        },
        saveItem(state) {
            let index = state.order.fabricShadeDayNightOrderItems.findIndex(
                item => item.id === state.item.id
            );
            Vue.set(
                state.order.fabricShadeDayNightOrderItems,
                index,
                simpleClone(state.item)
            );

            state.item.shortDescription = null;
            state.item.width = 0;
            state.item.glassWidth = 0;
            state.item.height = 0;
            state.item.glassHeight = 0;
            state.item.fabricWidth = 0;
            delete state.item["@id"]
            state.item.id = null
        },
        addAutomationItem(state, automationItem) {
            state.order.automationOrderItems.push(simpleClone(automationItem))
        },
        addItem(state) {
            if (!state.item.globalDrive) {
                notify(t.t("messages.item_has_no_drive"), "warning");
                return;
            }
            state.order.fabricShadeDayNightOrderItems.push(simpleClone(state.item));
            state.item.shortDescription = null;
            state.item.width = 0;
            state.item.glassWidth = 0;
            state.item.height = 0;
            state.item.glassHeight = 0;
            state.item.fabricWidth = 0;
            notify("Dodano pozycję do zamówienia");
        },
        removeAutomationItem(state, index) {
            state.order.automationOrderItems.splice(index, 1);
        },
        removeItem(state, index) {
            state.order.fabricShadeDayNightOrderItems.splice(index, 1);
        },
        updateCustomer(state, customer) {
            state.order.customer = customer;
        },
        setItem(state, {item}) {
            state.item = simpleClone(item);
        },
        editItem(state, {index, item}) {
            state.editIndex = index;
            state.item = simpleClone(item);

            const {globalDriveType, globalDriveSeries} = simpleClone(item.globalDrive)
            state.driveTypeFilter = globalDriveType
            state.driveSeriesFilter = globalDriveSeries

            state.changeStatusesForEditItem = simpleClone(
                defaultChangeStatusesForItemWhileEdit
            );
        },
        resetEditIndex(state) {
            state.editIndex = null;
        },
        resetChangeStatusesForEditItem(state) {
            state.changeStatusesForEditItem = simpleClone(
                defaultChangeStatusesForItemWhileEdit
            );
        },
        setItemFabricShadeDayNight(state, fabricShadeDayNight) {
            state.item.fabricShadeDayNight = fabricShadeDayNight;
        },
        setItemFabricShadeDayNightGlobalAccessoryEntry(
            state,
            fabricShadeDayNightGlobalAccessoryEntry
        ) {
            state.item.fabricShadeDayNightGlobalAccessoryEntry = fabricShadeDayNightGlobalAccessoryEntry;
        },
        setItemFabricShadeDayNightGlobalBalkEntry(state, fabricShadeDayNightGlobalBalkEntry) {
            state.item.fabricShadeDayNightGlobalBalkEntry = fabricShadeDayNightGlobalBalkEntry;
        },
        setItemFabricShadeDayNightGlobalRunnerEntry(state, fabricShadeDayNightGlobalRunnerEntry) {
            state.item.fabricShadeDayNightGlobalRunnerEntry = fabricShadeDayNightGlobalRunnerEntry;
        },
        setItemFabricShadeDayNightGlobalFixingEntry(state, fabricShadeDayNightGlobalFixingEntry) {
            state.item.fabricShadeDayNightGlobalFixingEntry = fabricShadeDayNightGlobalFixingEntry;
        },
        setItemFabricShadeDayNightGroup(state, fabricShadeDayNightGroup) {
            state.item.fabricShadeDayNightGroup = fabricShadeDayNightGroup;
        },
        setItemFabric(state, fabric) {
            state.item.fabricDayNight = fabric;
        },
        setItemFabricColor(state, fabricColorDayNight) {
            state.item.fabricColorDayNight = fabricColorDayNight;
        },
        setItemGlobalPipe(state, globalPipe) {
            state.item.globalPipe = globalPipe;
        },
        setItemGlobalDrive(state, globalDrive) {
            state.item.globalDrive = globalDrive;
        },
        setItemGlobalDriveControl(state, globalDriveControl) {
            state.item.globalDriveControl = globalDriveControl;
        },
        setItemGlobalRemoteControl(state, globalRemoteControl) {
            state.item.globalRemoteControl = globalRemoteControl;
        },
        setItemCompanyInstallationValue(state, companyInstallationValue) {
            state.item.companyInstallationValue = companyInstallationValue;
        },
        setCustomerByGusApiData(state, gusData) {
            state.order.customer.companyName = gusData.name;
            state.order.customer.taxId = gusData.nip;
            state.order.customer.zipCode = gusData.zipCode;
            state.order.customer.city = gusData.city;
            state.order.customer.streetAndNumber = `${gusData.street} ${gusData.propertyNumber}`;
            state.order.customer.firstName = null;
            state.order.customer.lastName = null;
            state.order.customer.phone = null;
        },
        resetCustomer(state) {
            if (state.order.customer.id) {
                delete state.order.customer.id;
                delete state.order.customer["@id"];
                delete state.order.customer["@type"];
            }
            state.order.customer.companyName = null;
            state.order.customer.taxId = null;
            state.order.customer.zipCode = null;
            state.order.customer.city = null;
            state.order.customer.streetAndNumber = null;
            state.order.customer.firstName = null;
            state.order.customer.lastName = null;
            state.order.customer.phone = null;
            state.order.customer.email = null;
        },
        setCustomer(state, customer) {
            state.order.customer = customer;
        },
    },
    actions: {
        setExtraPackageNote({commit}, payload) {
            commit("setExtraPackageNote", payload);
        },
        deleteError({commit}, errorName) {
            commit("deleteError", errorName);
        },
        toggleShowForm({commit}) {
            commit("toggleShowForm");
        },
        setIsMessageBorderAnimating({commit}, payload) {
            commit("setIsMessageBorderAnimating", payload);
        },
        setMessage({commit}, payload) {
            commit("setMessage", payload);
        },
        setDriveTypeFilter({commit}, payload) {
            commit("setDriveTypeFilter", payload);
        },
        setDriveSeriesFilter({commit}, payload) {
            commit("setDriveSeriesFilter", payload);
        },
        cancelEdit({commit, dispatch}) {
            commit("resetEditIndex");
            commit("resetChangeStatusesForEditItem");
            dispatch("setItemDefaults");
        },
        setItemDefaults({commit, getters, rootState}) {
            let item = simpleClone(defaultItem);
            item.shortDescription = null;
            commit("setItem", {item});

            const fabricShadeDayNight = simpleClone(getters.fabricShadeDayNights[0]);

            commit("setItemFabricShadeDayNight", fabricShadeDayNight);
            commit(
                "setItemFabricShadeDayNightGlobalAccessoryEntry",
                getters.itemFabricShadeDayNightGlobalAccessoryEntries[0]
            );
            commit(
                "setItemFabricShadeDayNightGlobalBalkEntry",
                getters.itemFabricShadeDayNightGlobalBalkEntries[0]
            );
            commit(
                "setItemFabricShadeDayNightGlobalRunnerEntry",
                getters.itemFabricShadeDayNightGlobalRunnerEntries[0]
            );
            commit(
                "setItemFabricShadeDayNightGlobalFixingEntry",
                fabricShadeDayNight.fabricShadeDayNightGlobalFixingEntries[0]
            );
            commit("setItemFabricShadeDayNightGroup", getters.fabricShadeDayNightGroups[0]);

            if (
                getters.item.fabricShadeDayNightGroup &&
                getters.item.fabricShadeDayNightGroup.fabricDayNights.length > 0
            ) {
                commit("setItemFabric", getters.item.fabricShadeDayNightGroup.fabricDayNights[0]);
                if (getters.item.fabricDayNight.defaultFabricColor) {
                    commit("setItemFabricColor", getters.item.fabricDayNight.defaultFabricColor);
                }
            }

            commit("setItemGlobalPipe", getters.fabricShadeDayNights[0].globalPipe);
            // jeśli typ napędu istnieje (ustawienie domyslnego sterownika i lancuszka)
            if (getters.item.globalPipe) {
                //jeśli istnieją napędy to ustaw pierwszy domyślnie
                if (getters.item.globalPipe.globalDrives.length > 0) {
                    commit("setItemGlobalDrive", getters.item.globalPipe.globalDrives[0]);

                    let found = false;
                    getters.item.globalPipe.globalDrives.forEach(drive => {
                        if (drive.globalDriveType && !found) {
                            commit("setDriveTypeFilter", drive.globalDriveType);
                            found = true;
                        }
                    });
                }
                //jesli istnieja sterowania dla napedu to ustaw pierwszy
                if (
                    getters.item.globalDrive &&
                    getters.item.globalDrive.globalDriveControls.length > 0
                ) {
                    commit(
                        "setItemGlobalDriveControl",
                        getters.item.globalDrive.globalDriveControls[0]
                    );
                }
                //jeśi istnieja piloty dla napędu to ustaw pierwszy z napędu lub sterowania
                if (
                    getters.item.globalDrive &&
                    getters.item.globalDrive.globalRemoteControls.length > 0
                ) {
                    commit(
                        "setItemGlobalRemoteControl",
                        getters.item.globalDrive.globalRemoteControls[0]
                    );
                } else if (
                    getters.item.globalDriveControl &&
                    getters.item.globalDriveControl.globalRemoteControls.length > 0
                ) {
                    commit(
                        "setItemGlobalRemoteControl",
                        getters.item.globalDriveControl.globalRemoteControls[0]
                    );
                }
                if (rootState.company.companyInstallationValues.length > 0) {
                    const installationArray = objectToCollection(
                        rootState.company.companyInstallationValues
                    );

                    let array = installationArray.filter(
                        civ =>
                            civ.fabricShadeDayNights &&
                            civ.fabricShadeDayNights.findIndex(
                                fs => fs === getters.item.fabricShadeDayNight["@id"]
                            ) > -1
                    );
                    if (array.length > 0) {
                        commit("setItemCompanyInstallationValue", array[0]);
                    }
                }
            }
        },
        async saveCustomer({commit, state}, type = "create") {
            commit("startLoading");
            let error = {field: null, message: null};
            let isError = false;
            if (state.order.customer.type === "person") {
                if (!state.order.customer.lastName || state.order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!state.order.customer.city || state.order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (state.order.customer.type === "company") {
                if (
                    !state.order.customer.companyName ||
                    state.order.customer.companyName === "" ||
                    !state.order.customer.taxId ||
                    state.order.customer.taxId === "" ||
                    !state.order.customer.city ||
                    state.order.customer.city === ""
                ) {
                    if (
                        !state.order.customer.companyName ||
                        state.order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!state.order.customer.taxId || state.order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!state.order.customer.city || state.order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (state.order.customer.companyName && state.order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (state.order.customer.taxId && state.order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }
            if (type === "create" && state.order.customer.id) {
                delete state.order.customer.id;
                delete state.order.customer["@id"];
                delete state.order.customer["@type"];
            }
            let method = type === "create" ? "post" : "put";
            let url = type === "create" ? "/customers" : `/customers/${state.order.customer.id}`;
            await http[method](url, state.order.customer)
                .then(response => {
                    const data = response.data;
                    notify(t.t("customer_updated"));
                    commit("updateCustomer", data);
                })
                .catch(error => {
                    const resp = error.response;
                    if (resp) {
                        if (resp.status === 400) {
                            const violations = resp.data.violations;
                            violations.forEach(violation => {
                                notify(
                                    `${t.t("field")} ${t.t(violation.propertyPath)} ${t.t(
                                        violation.message
                                    )}`,
                                    "warning"
                                );
                            });
                        }
                    }
                });
            commit("endLoading");
        },
        async removeAutomationItem({commit, dispatch, state}, index) {
            commit("removeAutomationItem", index);
            if (state.editIndex === index) {
                dispatch("setItemDefaults");
            }
            await dispatch("updateOrder");
        },
        async removeItem({commit, dispatch, state}, index) {
            dispatch("updateClearOrder");
            commit("removeItem", index);
            commit("resetChangeStatusesForEditItem");
            if (state.editIndex === index) {
                dispatch("setItemDefaults");
            }
            await dispatch("updateOrder");
        },
        async saveAutomationItem({commit, dispatch}, item) {
            commit("addAutomationItem", item);
            notify("Dodano pozycję do zamówienia");

            await dispatch("updateOrder");
        },
        async saveItemAndClose({dispatch}) {
            dispatch("saveItem").then(dispatch("valuateOrder"));
        },
        async saveItem({commit, dispatch, getters}) {
            if (getters.isEdit) {
                commit("saveItem");
                commit("resetEditIndex");
                commit("resetChangeStatusesForEditItem");
                // dispatch("setItemDefaults");
                notify("Zmieniono pozycję zamówienia");
            } else {
                commit("addItem");
            }
            await dispatch("updateOrder");
        },
        async clearOrder({state, dispatch, commit}) {
            let order = simpleClone(state.order);
            dispatch("updateClearOrder", {order})
            state.order.fabricShadeDayNightOrderItems = [];
            state.order.automationOrderItems = [];

            state.order.netSalesValue = 0;
            state.order.salesGrossValue = 0;
            state.order.totalSalesNetValue = 0;
            state.order.leftToPayValue = 0;
            state.order.netSalesValueAfterDiscount = 0;
            state.order.percentDiscount = 0;
            state.order.withInstallation = false;
            state.order.installationValue = 0;
            state.order.installationValuePerItem = 0;
            state.order.fitterTravelCost = null;
            state.order.fitterTravelCostValue = 0;
            state.order.advanceValue = 0;
            state.order.discountValue = 0;
            state.order.packageNote = "";
            state.order.notes = "";

            commit("resetCustomer")
            commit("setExtraPackageNote", "");

            state.editIndex = null;
            dispatch("updateOrder");
        },
        updateClearOrder: async function (state, {order}) {
            const orderData = prepareOrderToSent(order);
            orderData.customer = null

            http.put(`/orders/${order.id}/clear`, orderData);
        },
        updateOrder: debounce(async function ({commit, getters, rootState}) {
            // commit("common/startLoading", null, {root: true})
            let order = simpleClone(getters.order);
            let customer = simpleClone(getters.order.customer);
            if (!customer.id || !customer["@id"]) {
                order.customer = null;
            }
            order = prepareOrderToSent(order);

            if (!order.number) {
                order.number = order.id;
            }

            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }

            let {data} = await http.put(`/orders/${order.id}`, order);

            if (data.percentDiscount !== order.percentDiscount) {
                commit("addError", {
                    field: "percentDiscount",
                    message: "percent_discount_exceeded"
                });
            } else {
                commit("deleteError", "percentDiscount");
            }

            if (!customer.id || !customer["@id"]) {
                data.customer = customer;
            }
            commit("setOrder", data);
            // commit("common/endLoading", null, {root: true})
        }, 1000),
        async placeOrder(
            {state, commit, dispatch, rootState, rootGetters},
            isEditing = false
        ) {
            if (state.order.fabricShadeDayNightOrderItems.length === 0) {
                notify(t.t("order_cant_be_empty"), "warning");
                return;
            }

            commit("startLoading");
            let order = simpleClone(state.order);
            order = prepareOrderToSent(order);
            if (!order.number) {
                order.number = order.id;
            }

            let error = {field: null, message: null};
            let isError = false;
            if (order.customer.type === "person") {
                if (!order.customer.lastName || order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!order.customer.city || order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (order.customer.type === "company") {
                if (
                    !order.customer.companyName ||
                    order.customer.companyName === "" ||
                    !order.customer.taxId ||
                    order.customer.taxId === "" ||
                    !order.customer.city ||
                    order.customer.city === ""
                ) {
                    if (
                        !order.customer.companyName ||
                        order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.taxId || order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.city || order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (order.customer.companyName && order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (order.customer.taxId && order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }
            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }

            let note = order.packageNote || "";

            if (state.extraPackageNote) {
                if(order.packageNote)note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
                note = state.extraPackageNote +  "." + note;
            }

            order.packageNote = note;

            const {data} = await http.put(
                `/orders/${state.order.id}/complete`,
                order
            );
            commit("setExtraPackageNote", "");
            if (data.status === "new") {
                // redirect to order show
            }

            if (isEditing) {
                await router.push({name: "fabric_shade_dayf_nights_create_order"});
            } else {
                // asking api for draft order as on launch and set defaults values for item after placing order
                dispatch("getOrder");
                dispatch("setItemDefaults");
            }
            commit("endLoading");
            notify(t.t("new_complete_order_added"));

            notify(t.t("new_complete_order_added_thanks", {orderId: orderNumberCreator(order.id)}) + "<br>" +
                t.t("new_complete_order_added_price", {price: rootGetters["common/formatPrice"](order.totalPurchaseGrossValue)}) + "<br>" +
                t.t('new_complete_order_added_more'), "success", "is-top", 7000);
        },
        async valuateOrder(
            {state, commit, dispatch, rootState},
            isEditing = false
        ) {
            if (state.order.fabricShadeDayNightOrderItems.length === 0) {
                notify(t.t("order_cant_be_empty"), "warning");
                return;
            }

            commit("startLoading");
            let order = simpleClone(state.order);
            order = prepareOrderToSent(order);

            if (!order.number) {
                order.number = order.id;
            }

            let error = {field: null, message: null};
            let isError = false;
            if (order.customer.type === "person") {
                if (!order.customer.lastName || order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!order.customer.city || order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (order.customer.type === "company") {
                if (
                    !order.customer.companyName ||
                    order.customer.companyName === "" ||
                    !order.customer.taxId ||
                    order.customer.taxId === "" ||
                    !order.customer.city ||
                    order.customer.city === ""
                ) {
                    if (
                        !order.customer.companyName ||
                        order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.taxId || order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.city || order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (order.customer.companyName && order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (order.customer.taxId && order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }

            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }

            let note = order.packageNote || "";

            if (state.extraPackageNote) {
                if(order.packageNote)note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
                note = state.extraPackageNote +  "." + note;
            }

            order.packageNote = note;

            const {data} = await http.put(
                `/orders/${state.order.id}${isEditing ? "" : "/valuation"}`,
                order
            );
            commit("setExtraPackageNote", "");
            if (data.status === "new") {
                // redirect to order show
            }

            if (isEditing) {
                await router.push({name: "fabric_shade_day_nights_create_order"});
            } else {
                // asking api for draft order as on launch and set defaults values for item after placing order
                dispatch("getOrder");
                dispatch("setItemDefaults");
            }

            commit("endLoading");
            notify(isEditing ? t.t("valuation_saved") : t.t("new_valuation_added"));
        },
        // promise function to get order for copy
        async getOrderForCopy({commit, dispatch}, id) {
            try {
                const order = (await http.get(`/orders/${id}`)).data;

                //wait for all recalculate, so use it as promise
                await Promise.all(
                  order.fabricShadeDayNightOrderItems.map(item => {
                      delete item.id;
                      return dispatch("recalculateOrderItem", item);
                  }))
                commit("setFabricShadeDayNightOrderItems", order.fabricShadeDayNightOrderItems);
                commit("setCustomer", order.customer);
                dispatch("updateOrder");


            } catch (e) {
                notify(t.t("order_copy_error", {id}), "error", "is-top", 7000);
            }
        },
        async recalculateOrderItem({state},item) {
            state.priceLoading = true;
            item = simpleClone(item);
            item = prepareItemToSent(item);
            delete item["@id"];
            delete item.id;
            await http.post(`/calculate/fabric_shade_day_night_order_item`, item)
              .then(({data}) => {
                  item.netPurchaseValue = data.netPurchaseValue;
                  item.netSalesValue = data.netSalesValue;
              })
              .catch(error => {
                  notify(error.response.data["hydra:description"], "danger");
              });
        },
        async getOrder({commit, rootGetters}, id = null) {
            //check if id is set, if yes, its valuation edit mode
            let order = null;
            if (id !== null && !isNaN(id)) {
                let response = await http.get(`/orders/${id}`);
                order = response.data;
            } else {
                // find drafts
                let {data} = await http.get(
                    `/orders?${store.getters["auth/companyTypeForUrlProp"]}.id=${store.getters["auth/companyId"]}&status=draft&category=fabric_shade_day_night&properties[]=id`
                );
                // get first draft by id as order
                if (data["hydra:member"].length > 0) {
                    let first = data["hydra:member"][0];
                    let response = await http.get(`/orders/${first.id}`);
                    order = response.data;
                } else {
                    let params = {
                        category: "fabric_shade_day_night",
                        customer: null
                    };
                    // create new order
                    let response = await http.post("/orders", params);
                    order = response.data;
                }
                if (order.customer === null) {
                    order.customer = {
                        type: "person",
                        companyName: null,
                        firstName: null,
                        lastName: null,
                        zipCode: null,
                        city: null,
                        streetAndNumber: null,
                        phone: null,
                        email: null,
                        taxId: null
                    };
                }
                if (!order.name) {
                    order.name = `Wycena numer ${orderNumberCreator(order.id)}`;
                }
                if (
                    !order.companyVatRate &&
                    objectToCollection(rootGetters["company/companyVatRates"]).length > 0
                ) {
                    let first = null;
                    for (const rate in rootGetters["company/companyVatRates"]) {
                        if (first === null) {
                            first = rate;
                        }
                        if (
                            rootGetters["company/companyVatRates"][rate].name.includes(
                                "23"
                            ) ||
                            rootGetters["company/companyVatRates"][rate].value
                                .toString()
                                .includes("23")
                        ) {
                            order.companyVatRate =
                                rootGetters["company/companyVatRates"][rate]["@id"];
                            break;
                        }
                    }
                    if (!order.companyVatRate) {
                        order.companyVatRate =
                            rootGetters["company/companyVatRates"][first]["@id"];
                    }
                }

                if (
                    rootGetters["company/defaultDeliveryMethod"] &&
                    !order.deliveryMethod &&
                    objectToCollection(rootGetters["company/deliveryMethods"]).length > 0
                ) {
                    let first = null;
                    for (const delivery in rootGetters["company/deliveryMethods"]) {
                        if (first === null) {
                            first = delivery;
                        }
                        if (
                            rootGetters["company/deliveryMethods"][delivery]["@id"] ===
                            rootGetters["company/defaultDeliveryMethod"]
                        ) {
                            order.deliveryMethod =
                                rootGetters["company/deliveryMethods"][delivery];
                            break;
                        }
                    }
                    if (!order.deliveryMethod) {
                        order.deliveryMethod =
                            rootGetters["company/deliveryMethods"][first];
                    }
                }

                if (
                    !order.paymentMethod &&
                    objectToCollection(rootGetters["company/paymentMethods"]).length > 0
                ) {
                    for (const cost in rootGetters["company/paymentMethods"]) {
                        order.paymentMethod = rootGetters["company/paymentMethods"][cost];
                        break;
                    }
                }
            }
            commit("setOrder", order);
        },
        async init({commit, dispatch}) {
            // commit("startLoading");
            let [
                fabricShadeDayNights,
                // fabricShadeDayNightGlobalAccessoryEntries,
                // fabricShadeDayNightGlobalBalkEntries,
                // fabricShadeDayNightGlobalRunnerEntries,
                fabricShadeDayNightGroups
                // fabricShadeDayNightGlobalFixingEntries
            ] = await Promise.all([
                http.get(
                    "/fabric_shade_day_nights?" +
                    generateProperties(
                        [],
                        [
                            "id",
                            "name",
                            "picture",
                            "thumbnail",
                            "message",
                            "minWidth",
                            "maxWidth",
                            "minHeight",
                            "maxHeight",
                            "fabricWidth",
                            "fabricWidthOperator",
                            "surveyPicture",
                            "mechanismPicture",
                            "previewType",
                            "isWithGlassSizes",
                            "measurementInstruction",
                            "surveyDescriptionPL",
                            "surveyDescriptionCZ",
                            "surveyDescriptionEN",
                            "surveyDescriptionDE",
                            "surveyDescriptionFR",
                            "surveyDescriptionSE",
                            "surveyDescriptionIT",
                            "surveyDescriptionSI",
                            "surveyDescriptionRU",
                            "surveyDescriptionES",
                            "surveyDescriptionNL",
                            "productionFabricHeight"
                        ]
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalFixingEntries"],
                        ["isEnabled"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalFixingEntries", "globalFixing"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalRunnerEntries", "globalRunner"],
                        ["id", "name", "thumbnail", "picture", "message"],
                        true
                    ) +
                    generateProperties(
                        [
                            "fabricShadeDayNightGlobalRunnerEntries",
                            "globalRunner",
                            "fabricShadeColors"
                        ],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalBalkEntries"],
                        ["id"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalBalkEntries", "globalBalk"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        [
                            "fabricShadeDayNightGlobalBalkEntries",
                            "globalBalk",
                            "fabricShadeColors"
                        ],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightGlobalAccessoryEntries", "globalAccessory"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        [
                            "fabricShadeDayNightGlobalAccessoryEntries",
                            "globalAccessory",
                            "fabricShadeColors"
                        ],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        [
                            "fabricShadeDayNightGlobalAccessoryEntries",
                            "fabricShadeDayNightGlobalBalkEntry"
                        ],
                        ["id"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightFabricShadeDayNightAdditionalEntries"],
                        ["isEnabled"],
                        true
                    ) +
                    generateProperties(
                        ["fabricShadeDayNightFabricShadeDayNightAdditionalEntries", "fabricShadeDayNightAdditional"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        ["globalPipes"],
                        ["id", "name"],
                        true) +
                    generateProperties(
                        ["globalPipes", "globalDrives"],
                        ["id", "name", "picture", "thumbnail", "message", "minWidth", "maxWidth", "liftingCapacity", "liftingCapacityNm", "pieceSurcharge"],
                        true
                    ) +
                    generateProperties(
                        ["globalPipes", "globalDrives", "globalDriveType"],
                        ["id", "name", "message"],
                        true
                    ) +
                    generateProperties(
                        ["globalPipes", "globalDrives", "globalDriveSeries"],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        ["globalPipes", "globalDrives", "globalDriveControls"],
                        ["id", "name", "picture", "thumbnail", "pieceSurcharge", "isEnabled", "message", "message", "symbol", "manufacturer", "globalDriveControlType", "globalRemoteControlChannel", "isRemoteControl", "description"],
                        true
                    )
                ),
                http.get("/fabric_shade_day_night_groups"),

            ]);
            commit("setFabricShadeDayNights", fabricShadeDayNights.data["hydra:member"]);
            commit("setFabricShadeDayNightGroups", fabricShadeDayNightGroups.data["hydra:member"]);
            dispatch("setItemDefaults");
            // commit("endLoading");
        },
        async fetchGus({state, commit}) {
            const nip = state.order.customer.taxId
                .replace(/^\D+/g, "")
                .replaceAll("-", "");
            commit("startGusFetching");
            try {
                let {data} = await http.get(`/gus/nip/${nip}`);
                commit("setCustomerByGusApiData", data);
            } catch (e) {
                commit("endGusFetching");
                window.alert(t.t("gus_problem"));
            }
            commit("endGusFetching");
        },
        resetCustomer({commit}) {
            commit("resetCustomer");
        },

    }
};
