import store from "store";
import http from "../../http";

export default {
  namespaced: true,
  state: {
    username: store.get("username") || null,
    type: store.get("type") || null,
    userId: store.get("userId") || null,
    companyId: store.get("companyId") || null,
    token: store.get("token") || null,
    refresh_token: store.get("refresh_token") || null,
    email: store.get("email") || null,
    permissions: store.get("permissions") || [],
    isLoggedIn: !!store.get("token"),
    companyType: store.get("companyType") || null,
    firstName: store.get("firstName") || null,
    lastName: store.get("lastName") || null
  },
  getters: {
    fullName(state) {
      return `${state.firstName || ""} ${state.lastName || ""}`;
    },
    userId(state) {
      return state.userId;
    },
    userUrl() {
      return "users"
    },
    token(state) {
      return state.token;
    },
    userType(state) {
      return state.type;
    },
    companyId(state) {
      return state.companyId;
    },
    companyUrl(state) {
      const type = state.companyType;
      if ("company" === type) {
        return "companies";
      } else if ("sub_company" === type) {
        return "sub_companies";
      }
    },
    companyType(state) {
      return state.companyType;
    },
    companyTypeForUrlProp(state) {
      const type = state.companyType;
      if ("company" === type) {
        return "company";
      } else if ("sub_company" === type) {
        return "subCompany";
      }
    },
    hasPermission: (state) => (permission) => {
      return state.permissions.some(item => item.code === permission);
    },
  },
  mutations: {
    setData(state, data) {
      store.set("username", data.username);
      store.set("type", data.type);
      store.set("userId", data.userId);
      store.set("token", data.token);
      store.set("refresh_token", data.refresh_token);
      store.set("email", data.email);
      store.set("companyId", data.companyId);
      store.set("permissions", data.permissions);
      store.set("companyType", data.companyType);
      store.set("firstName", data.firstName);
      store.set("lastName", data.lastName);
      state.userId = data.userId;
      state.username = data.username;
      state.type = data.type;
      state.token = data.token;
      state.refresh_token = data.refresh_token;
      state.email = data.email;
      state.permissions = data.permissions;
      state.companyId = data.companyId;
      state.isLoggedIn = true;
      state.companyType = data.companyType;
      state.firstName = data.firstName;
      state.lastName = data.lastName;
    },
    setRefreshedTokens(state, data) {
      store.set("token", data.token);
      store.set("refresh_token", data.refresh_token);
      state.token = data.token;
      state.refresh_token = data.refresh_token;
    },
    removeData(state) {
      store.set("username", null);
      store.set("type", null);
      store.set("userId", null);
      store.set("token", null);
      store.set("refresh_token", null);
      store.set("email", null);
      store.set("permissions", null);
      store.set("companyId", null);
      store.set("companyType", null);
      store.set("firstName", null);
      store.set("lastName", null);
      state.username = null;
      state.type = null;
      state.token = null;
      state.userId = null;
      state.refresh_token = null;
      state.email = null;
      state.permissions = null;
      state.companyId = null;
      state.isLoggedIn = false;
      state.companyType = null;
      state.firstName = null;
      state.lastName = null;
    }
  },
  actions: {
    async postLogin({ commit }, params) {
      try {
        let {data} = await http.post("/authentication_token", params);
        if (data.type !== "company_owner" && data.type !== "company_worker") {
          throw new Error("Login filed");
        }
        commit("setData", data);
      } catch (e) {
        if (e.response && e.response.status === 403) {
          throw new Error("must_change_password");
        } else {
          throw new Error("Failed to log in.");
        }
      }
    },
    async resetPassword({commit}, params, removeData) {
      try {
        await http.post("/reset-password", params);
        if (removeData)commit("removeData");
      } catch (e) {
        throw new Error("Nie udało się zresetować hasła");
      }
    },
    async changePassword({commit}, params) {
      try {
        await http.post(`/reset-password/change/${params.token}`, params);
        commit("removeData");
      } catch (e) {
        throw new Error("Nie udało się zmienić hasła" + e.toString());
      }
    },
    logout({commit}) {
      commit("removeData");
    }
  }
};
