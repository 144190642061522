import http from "../../http";
import simpleClone from "../../helpers/simpleClone";
import generateProperties from "@/helpers/generateProperties";
import notify from "@/helpers/notify";
import Vue from "vue";
import t from "@/i18n";
import router from "@/router";
import prepareOrderToSent from "@/helpers/prepareOrderToSent";
import orderNumberCreator from "@/helpers/orderNumberCreator";
import objectToCollection from "@/helpers/objectToCollection";
import prepareItemToSent from "../../helpers/prepareItemToSent";
import store from "../../store";

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this,
            args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

let defaultChangeStatusesForItemWhileEdit = {
    accessory: false,
    balk: false,
    pleatGroup: false,
    pleatFabricColor: false,
    pleatFabric: false,
    pleatGroupBottom: false,
    pleatFabricColorBottom: false,
    pleatFabricBottom: false,
    runner: false,
    additionalString: false
};

let defaultItem = {
    quantity: 1,
    pleatWidth: 0,
    width: 0,
    height: 0,
    pleatFabricColor: null,
    pleatFabricColorBottom: null,
    previewType: 'free',
    pleatFabric: null,
    pleatFabricBottom: null,
    pleat: null,
    pleatGlobalRunnerEntry: null,
    pleatPleatFixingTypeEntry: null,
    pleatPleatRemoteControlTypeEntry: null,
    pleatGlobalAccessoryEntry: null,
    pleatAdditionalString:null,
    pleatGroup: null,
    pleatGroupBottom:null,
    netPurchaseValue: 0,
    netSalesValue: 0,
    companyInstallationValue: null,
    shortDescription: null,
};
let defaultOrder = {
    name: "",
    category: "",
    pleatOrderItems: [],
    automationOrderItems: [],
    customer: {
        type: "person",
        companyName: null,
        firstName: null,
        lastName: null,
        zipCode: null,
        city: null,
        streetAndNumber: null,
        phone: null,
        email: null,
        taxId: null
    },
    netSalesValue: 0,
    salesGrossValue: 0,
    totalSalesNetValue: 0,
    leftToPayValue: 0,
    netSalesValueAfterDiscount: 0,
    percentDiscount: 0,
    withInstallation: false,
    installationValue: 0,
    installationValuePerItem: 0,
    fitterTravelCost: null,
    fitterTravelCostValue: 0,
    advanceValue: 0,
    vatRate: null,
    vatRateValue: 0,
    companyVatRate: null,
    salesVatRateValue: 0,
    paymentMethod: null,
    deliveryMethod: null,
    discountValue: 0,
    packageNote: "",
    notes: ""
};
export default {
    namespaced: true,
    state: {
        extraPackageNote: "",
        showForm: true,
        errors: [],
        loading: false,
        pleats: [],
        pleatGlobalAccessoryEntries: [],
        pleatGlobalBalkEntries: [],
        pleatGlobalRunnerEntries: [],
        pleatPleatFixingTypeEntries: [],
        pleatPleatAdditionalEntries: [],
        pleatAdditionalStrings:[],
        pleatGroups: [],
        traitFabric: null,
        traitColor: null,
        pleatFabricColor: null,
        item: simpleClone(defaultItem),
        order: simpleClone(defaultOrder),
        editIndex: null,
        gusFetching: false,
        message: null,
        isMessageBorderAnimating: false,
        changeStatusesForEditItem: simpleClone(
            defaultChangeStatusesForItemWhileEdit
        )
    },
    getters: {
        extraPackageNote: state => state.extraPackageNote,
        orderNumber: state => {
            let number = "";
            if (state.order && state.order.id) {
                for (let i = state.order.id.toString().length; i < 6; i++) {
                    number += "0";
                }
                number += state.order.id.toString();
            }
            return number;
        },
        customErrors: state => state.customErrors,
        showForm: state => state.showForm,
        errors: state => state.errors,
        changeStatusesForEditItem: state => state.changeStatusesForEditItem,
        isMessageBorderAnimating: state => state.isMessageBorderAnimating,
        message: state => state.message,
        driveTypeFilter: state => state.driveTypeFilter,
        driveSeriesFilter: state => state.driveSeriesFilter,
        isRequiredAdditionalStrain: state => {
            if (!state.item || !state.item.pleat) {
                return false;
            }

            return state.item.pleat["isRequiredAdditionalStrain"];
        },
        itemDriveTypes: (state, getters) => {
            if (!getters.item || !getters.item.pleat || !getters.item.width) {
                return [];
            }

            const pipes = getters.item.pleat.globalPipes;
            if (!pipes || pipes.length === 0) {
                return [];
            }

            let distinctDrivesIndexes = [];
            let drives = [];
            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    if (
                        !distinctDrivesIndexes.includes(drive.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        drives.push(drive);
                        distinctDrivesIndexes.push(drive.id);
                    }
                });
            });

            let distinctIndexes = [];
            let filtered = [];

            drives.forEach(drive => {
                const type = drive.globalDriveType;
                if (type && !distinctIndexes.includes(type.id)) {
                    distinctIndexes.push(type.id);
                    filtered.push(type);
                }
            });
            return filtered;
        },
        itemDriveSeries: (state, getters) => {
            if (!getters.driveTypeFilter) {
                return [];
            }

            if (!getters.item || !getters.item.pleat || !getters.item.width) {
                return [];
            }

            const pipes = getters.item.pleat.globalPipes;
            if (!pipes || pipes.length === 0) {
                return [];
            }

            let distinctSeriesIndexes = [];
            let series = [];
            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    const seriesObj = drive.globalDriveSeries;
                    const type = drive.globalDriveType;
                    if (
                        seriesObj &&
                        type &&
                        type["@id"] === getters.driveTypeFilter["@id"] &&
                        !distinctSeriesIndexes.includes(seriesObj.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        series.push(seriesObj);
                        distinctSeriesIndexes.push(seriesObj.id);
                    }
                });
            });
            return series;
        },
        itemGlobalDrives: (state, getters) => {
            if (
                !getters.driveTypeFilter ||
                !getters.driveSeriesFilter ||
                !getters.item ||
                !getters.item.pleat ||
                !getters.item.width
            ) {
                return [];
            }

            const pipes = getters.item.pleat.globalPipes;
            if (!pipes) {
                return [];
            }

            let distinctDrivesIndexes = [];
            let drives = [];

            pipes.forEach(pipe => {
                pipe.globalDrives.forEach(drive => {
                    const series = drive.globalDriveSeries;
                    const type = drive.globalDriveType;
                    if (
                        series &&
                        type &&
                        series["@id"] === getters.driveSeriesFilter["@id"] &&
                        type["@id"] === getters.driveTypeFilter["@id"] &&
                        !distinctDrivesIndexes.includes(drive.id) &&
                        getters.item.width >= drive.minWidth &&
                        getters.item.width <= drive.maxWidth
                    ) {
                        drives.push(drive);
                        distinctDrivesIndexes.push(drive.id);
                    }
                });
            });

            return drives;
        },
        itemGlobalDriveControls: (state) => () => {
            return state.item.globalDrive ? state.item.globalDrive["globalDriveControls"] : []
        },
        byPleatColor: (state, getters) => pleatColor =>
            pleatColor["@id"] === getters.pleatColor,
        itemPleatGlobalAccessoryEntries: (state) => {
            const pleat = state.item.pleat;

            if (!pleat || !pleat.pleatGlobalAccessoryEntries) {
                return [];
            }

            return pleat.pleatGlobalAccessoryEntries
        },
        itemPleatGlobalRunnerEntries: (state) => {
            const pleat = state.item.pleat;

            if (!pleat || !pleat.pleatGlobalRunnerEntries) {
                return [];
            }

            return pleat.pleatGlobalRunnerEntries
        },
        itemPleatAdditionalStrings: (state) => {
            const pleat = state.item.pleat;
            if (!pleat) {
                return [];
            }
            if (!pleat.pleatAdditionalStrings) {
                return [];
            }
            return pleat.pleatAdditionalStrings;
        },
        itemPleatFabricColors: (state, getters) => {
            const fabric = state.item.pleatFabric;
            if (!fabric) {
                return [];
            }
            return fabric.pleatFabricColors.filter(fabricColor => {
                // jeśli jest filtr to wyklucz te kolory tkanin, które nie mają cechy z filtru
                if (getters.traitColor) {
                    if (
                        fabricColor.traitColors.findIndex(
                            traitColor => traitColor["@id"] === getters.traitColor
                        ) < 0
                    ) {
                        return false;
                    }
                }
                return true;
            });
        },
        itemPleatFabricColorBottoms: (state, getters) => {
            const fabric = state.item.pleatFabricBottom;
            if (!fabric) {
                return [];
            }
            return fabric.pleatFabricColors.filter(fabricColor => {
                // jeśli jest filtr to wyklucz te kolory tkanin, które nie mają cechy z filtru
                if (getters.traitColor) {
                    if (
                        fabricColor.traitColors.findIndex(
                            traitColor => traitColor["@id"] === getters.traitColor
                        ) < 0
                    ) {
                        return false;
                    }
                }
                return true;
            });
        },
        itemPleatFabrics: (state, getters) => {
            const pleatGroup = state.item?.pleatGroup;

            if (!pleatGroup || !pleatGroup.pleatFabrics) {
                return [];
            }

            let pleatFabrics = pleatGroup.pleatFabrics.filter(fabric => {
                if (
                    getters.traitFabric &&
                    fabric.traitFabrics &&
                    fabric.traitFabrics.findIndex(
                        trait => trait["@id"] === getters.traitFabric
                    ) < 0
                ) {
                    return false;
                }
                return true;
            });

            if (getters.traitColor) {
                pleatFabrics = pleatFabrics.filter(fabric => {
                    return (
                        0 <
                        fabric.pleatFabricColors.filter(shadeColor => {
                            return (
                                0 <
                                shadeColor.traitColors.filter(
                                    color => color["@id"] === getters.traitColor
                                ).length
                            );
                        }).length
                    );
                });
            }

            return pleatFabrics;
        },
        itemPleatFabricBottoms: (state, getters) => {
            const pleatGroup = state.item?.pleatGroupBottom;

            if (!pleatGroup || !pleatGroup.pleatFabrics) {
                return [];
            }

            let pleatFabrics = pleatGroup.pleatFabrics.filter(fabric => {
                if (
                    getters.traitFabric &&
                    fabric.traitFabrics &&
                    fabric.traitFabrics.findIndex(
                        trait => trait["@id"] === getters.traitFabric
                    ) < 0
                ) {
                    return false;
                }
                return true;
            });

            if (getters.traitColor) {
                pleatFabrics = pleatFabrics.filter(fabric => {
                    return (
                        0 <
                        fabric.pleatFabricColors.filter(shadeColor => {
                            return (
                                0 <
                                shadeColor.traitColors.filter(
                                    color => color["@id"] === getters.traitColor
                                ).length
                            );
                        }).length
                    );
                });
            }

            return pleatFabrics;
        },

        pleatColor: state => state.pleatColor,
        traitColor: state => state.traitColor,
        traitFabric: state => state.traitFabric,
        isEdit: (state, getters) => !!getters.item.id,
        item: state => state.item,
        editIndex: state => state.editIndex,
        order: state => state.order,
        loading: state => state.loading,
        gusFetching: state => state.gusFetching,
        pleats: state => state.pleats,
        pleatGlobalAccessoryEntries: state => {
            if (!state.pleatGlobalAccessoryEntries) {
                return [];
            }
            return state.pleatGlobalAccessoryEntries.filter(entry => {
                return !(
                    state.pleatColor !== null &&
                    0 ===
                    entry.globalAccessory.pleatColors.filter(
                        color => color["@id"] === state.pleatColor
                    ).length
                );
            });
        },
        pleatGlobalBalkEntries: state => {
            if (!state.pleatGlobalBalkEntries) {
                return [];
            }
            return state.pleatGlobalBalkEntries;
        },
        pleatGlobalRunnerEntries: state =>
            state.pleatGlobalRunnerEntries,
        pleatAdditionalStrings: state =>
            state.pleatAdditionalStrings,
        pleatGroups: state => state.pleatGroups,
        traits: state => ({
            traitColor: state.traitColor,
            traitFabric: state.traitFabric
        }),
        // unikalne cechy kolorów z kolorów tkanin z tkanin grupy
        pleatGroupTraitColorsSet: () => group => {
            let colors = new Set();
            group.pleatFabrics.forEach(fabric => {
                fabric.pleatFabricColors.forEach(fabricColor => {
                    fabricColor.traitColors.forEach(traitColor => {
                        colors.add(traitColor["@id"]);
                    });
                });
            });
            return colors;
        },
        // filtrowane grupy dla bottom fabric do selecta
        itemPleatGroupBottoms: (state, getters) => {
            let filteredGroups = state.pleatGroups.filter(group => {
                // jeśli wybrano kolor tkaniny wyklucz grupę, której
                // tkaniny nie mają w ich kolorach takiej cechy koloru
                if (
                    getters.traitColor !== null &&
                    !getters.pleatGroupTraitColorsSet(group).has(getters.traitColor)
                ) {
                    return false;
                }
                // jeśli wybrano cechę tkaniny to wyklucz tą grupę, która nie ma tkanin z takim zaciemnieniem
                if (
                    getters.traitFabric !== null &&
                    group.pleatFabrics.filter(
                        fabric =>
                            fabric.traitFabrics.findIndex(
                                trait => trait["@id"] === getters.traitFabric
                            ) >= 0
                    ).length <= 0
                ) {
                    return false;
                }
                // w pozostałych przypadkach dołącz grupę
                return true;
            });

            // podmienia nazwe grupy na liste symboli kolekcji tkanin
            return filteredGroups.map(group => {
                let name = `${group.position}. `;

                if (group.pleatFabrics && group.pleatFabrics.length > 0) {
                    group.pleatFabrics.forEach((fabric, index) => {
                        name += fabric.symbol;
                        if (index !== group.pleatFabrics.length - 1) {
                            name += ", ";
                        }
                    });
                }

                group.name = name;
                return group;
            });

        },
        itemPleatGroups: (state, getters) => {
            let filteredGroups = state.pleatGroups.filter(group => {
                // jeśli wybrano kolor tkaniny wyklucz grupę, której
                // tkaniny nie mają w ich kolorach takiej cechy koloru
                if (
                    getters.traitColor !== null &&
                    !getters.pleatGroupTraitColorsSet(group).has(getters.traitColor)
                ) {
                    return false;
                }
                // jeśli wybrano cechę tkaniny to wyklucz tą grupę, która nie ma tkanin z takim zaciemnieniem
                if (
                    getters.traitFabric !== null &&
                    group.pleatFabrics.filter(
                        fabric =>
                            fabric.traitFabrics.findIndex(
                                trait => trait["@id"] === getters.traitFabric
                            ) >= 0
                    ).length <= 0
                ) {
                    return false;
                }
                // w pozostałych przypadkach dołącz grupę
                return true;
            });

            // podmienia nazwe grupy na liste symboli kolekcji tkanin
            return filteredGroups.map(group => {
                let name = `${group.position}. `;

                if (group.pleatFabrics && group.pleatFabrics.length > 0) {
                    group.pleatFabrics.forEach((fabric, index) => {
                        name += fabric.symbol;
                        if (index !== group.pleatFabrics.length - 1) {
                            name += ", ";
                        }
                    });
                }

                group.name = name;
                return group;
            });

        },
        itemPleatPleatFixingTypeEntries: state => state.item.pleat.pleatPleatFixingTypeEntries,
        image: () => (object, imageName, extraField = null) => {
            if (extraField) {
                const field = object && object[extraField];
                if (field) {
                    const picture = field[imageName];
                    if (picture) {
                        return picture.contentUrl;
                    }
                    return null;
                }
                return null;
            }
            const picture = object && object[imageName];
            if (picture) {
                return picture.contentUrl;
            }
            return null;
        },

        pleatPleatAdditionalEntries: (state, getters) => {
            const pleat = getters.item.pleat;

            if (!pleat) return []

            if (pleat && pleat.pleatPleatAdditionalEntries.length > 0) {
                return pleat.pleatPleatAdditionalEntries
            }

            return []
        },
        itemPleatPleatRemoteControlTypeEntries: (state, getters) => {
            const pleat = getters.item.pleat;

            if(!pleat) return []

            if(pleat && pleat.pleatPleatRemoteControlTypeEntries.length > 0){
                return pleat.pleatPleatRemoteControlTypeEntries
            }

            return []
        }
    },
    mutations: {
        setExtraPackageNote(state, payload) {
            state.extraPackageNote = payload;
        },
        toggleShowForm(state) {
            state.showForm = !state.showForm;
        },
        addError(state, payload) {
            state.errors.push({
                propertyPath: payload.field,
                message: payload.message
            });
        },
        deleteError(state, name) {
            const index = state.errors.findIndex(
                error => error.propertyPath === name
            );
            if (index > -1) {
                Vue.delete(state.errors, index);
            }
        },
        setIsMessageBorderAnimating(state, payload) {
            state.isMessageBorderAnimating = payload;
        },
        setMessage(state, payload) {
            state.message = payload;
        },
        setDriveTypeFilter(state, payload) {
            state.driveTypeFilter = payload;
        },
        setDriveSeriesFilter(state, payload) {
            state.driveSeriesFilter = payload;
        },
        resetPleatColor(state) {
            state.pleatColor = null;
        },
        resetTraitColor(state) {
            state.traitColor = null;
        },
        resetTraitFabric(state) {
            state.traitFabric = null;
        },
        clearFilters(state) {
            state.traitColor = null;
            state.traitFabric = null;
            state.pleatColor = null;
        },
        setTraitColor(state, payload) {
            state.traitColor = payload;
        },
        setPleatColor(state, payload) {
            state.pleatColor = payload;
        },
        setTraitFabric(state, payload) {
            state.traitFabric = payload;
        },
        startLoading(state) {
            state.loading = true;
        },
        endLoading(state) {
            state.loading = false;
        },
        startGusFetching(state) {
            state.gusFetching = true;
        },
        endGusFetching(state) {
            state.gusFetching = false;
        },
        setPleatOrderItems(state, pleatOrderItems) {
            state.order.pleatOrderItems = pleatOrderItems;
        },
        setPleats(state, pleats) {
            state.pleats = pleats;
        },
        setPleatGlobalAccessoryEntries(
            state,
            pleatGlobalAccessoryEntries
        ) {
            state.pleatGlobalAccessoryEntries = pleatGlobalAccessoryEntries;
        },
        setPleatGlobalBalkEntries(state, pleatGlobalBalkEntries) {
            state.pleatGlobalBalkEntries = pleatGlobalBalkEntries;
        },
        setPleatGlobalRunnerEntries(state, pleatGlobalRunnerEntries) {
            state.pleatGlobalRunnerEntries = pleatGlobalRunnerEntries;
        },
        setPleatPleatFixingTypeEntries(state, pleatGlobalFixingsEntries) {
            state.pleatPleatFixingTypeEntries = pleatGlobalFixingsEntries;
        },
        setPleatGroups(state, pleatGroups) {
            state.pleatGroups = pleatGroups;
        },
        setOrder(state, order) {
            state.order = order;
        },
        saveItem(state) {
            let index = state.order.pleatOrderItems.findIndex(
                item => item.id === state.item.id
            );
            Vue.set(
                state.order.pleatOrderItems,
                index,
                simpleClone(state.item)
            );

            state.item.shortDescription = null;
            state.item.width = 0;
            state.item.height = 0;
            delete state.item["@id"]
            state.item.id = null
            notify("Dodano pozycję do zamówienia");
        },
        addAutomationItem(state, automationItem) {
            state.order.automationOrderItems.push(simpleClone(automationItem))
        },
        addItem(state) {
            state.order.pleatOrderItems.push(simpleClone(state.item));
            state.item.shortDescription = null;
            state.item.width = 0;
            state.item.height = 0;
            notify("Dodano pozycję do zamówienia");
        },
        removeItem(state, index) {
            state.order.pleatOrderItems.splice(index, 1);
        },
        removeAutomationItem(state, index) {
            state.order.automationOrderItems.splice(index, 1);
        },
        updateCustomer(state, customer) {
            state.order.customer = customer;
        },
        setItem(state, {item}) {
            state.item = simpleClone(item);
        },
        editItem(state, {index, item}) {
            state.editIndex = index;
            state.item = simpleClone(item);


            // const {globalDriveType, globalDriveSeries} = simpleClone(item.globalDrive)
            // state.driveTypeFilter = globalDriveType
            // state.driveSeriesFilter = globalDriveSeries

            state.changeStatusesForEditItem = simpleClone(
                defaultChangeStatusesForItemWhileEdit
            );
        },
        resetEditIndex(state) {
            state.editIndex = null;
        },
        resetChangeStatusesForEditItem(state) {
            state.changeStatusesForEditItem = simpleClone(
                defaultChangeStatusesForItemWhileEdit
            );
        },
        setItemPleat(state, pleat) {
            state.item.pleat = pleat;
        },
        setItemPleatGlobalAccessoryEntry(
            state,
            pleatGlobalAccessoryEntry
        ) {
            state.item.pleatGlobalAccessoryEntry = pleatGlobalAccessoryEntry;
        },
        setItemPleatGlobalBalkEntry(state, pleatGlobalBalkEntry) {
            state.item.pleatGlobalBalkEntry = pleatGlobalBalkEntry;
        },
        setItemPleatGlobalRunnerEntry(state, pleatGlobalRunnerEntry) {
            state.item.pleatGlobalRunnerEntry = pleatGlobalRunnerEntry;
        },
        setItemPleatPleatFixingTypeEntry(state, pleatPleatFixingTypeEntry) {
            state.item.pleatPleatFixingTypeEntry = pleatPleatFixingTypeEntry;
        },
        setItemPleatAdditionalString(state, pleatAdditionalString) {
            state.item.pleatAdditionalString = pleatAdditionalString;
        },
        setItemPleatGroup(state, pleatGroup) {
            state.item.pleatGroup = pleatGroup;
        },
        setItemPleatGroupBottom(state, pleatGroupBottom) {
            state.item.pleatGroupBottom = pleatGroupBottom;
        },
        setItemFabric(state, fabric) {
            state.item.pleatFabric = fabric;
        },
        setItemFabricBottom(state, fabric) {
            state.item.pleatFabricBottom = fabric;
        },
        setItemFabricColor(state, pleatFabricColor) {
            state.item.pleatFabricColor = pleatFabricColor;
        },
        setItemFabricColorBottom(state, pleatFabricColor) {
            state.item.pleatFabricColorBottom = pleatFabricColor;
        },
        setItemPleatPleatRemoteControlTypeEntry(state, remoteControlType) {
            state.item.pleatPleatRemoteControlTypeEntry = remoteControlType;
        },
        setItemCompanyInstallationValue(state, companyInstallationValue) {
            state.item.companyInstallationValue = companyInstallationValue;
        },
        setCustomerByGusApiData(state, gusData) {
            state.order.customer.companyName = gusData.name;
            state.order.customer.taxId = gusData.nip;
            state.order.customer.zipCode = gusData.zipCode;
            state.order.customer.city = gusData.city;
            state.order.customer.streetAndNumber = `${gusData.street} ${gusData.propertyNumber}`;
            state.order.customer.firstName = null;
            state.order.customer.lastName = null;
            state.order.customer.phone = null;
        },
        resetCustomer(state) {
            if (state.order.customer.id) {
                delete state.order.customer.id;
                delete state.order.customer["@id"];
                delete state.order.customer["@type"];
            }
            state.order.customer.companyName = null;
            state.order.customer.taxId = null;
            state.order.customer.zipCode = null;
            state.order.customer.city = null;
            state.order.customer.streetAndNumber = null;
            state.order.customer.firstName = null;
            state.order.customer.lastName = null;
            state.order.customer.phone = null;
            state.order.customer.email = null;
        },
        setCustomer(state, customer) {
            state.order.customer = customer;
        }
    },
    actions: {
        setExtraPackageNote({commit}, payload) {
            commit("setExtraPackageNote", payload);
        },
        deleteError({commit}, errorName) {
            commit("deleteError", errorName);
        },
        toggleShowForm({commit}) {
            commit("toggleShowForm");
        },
        setIsMessageBorderAnimating({commit}, payload) {
            commit("setIsMessageBorderAnimating", payload);
        },
        setMessage({commit}, payload) {
            commit("setMessage", payload);
        },
        setDriveTypeFilter({commit}, payload) {
            commit("setDriveTypeFilter", payload);
        },
        setDriveSeriesFilter({commit}, payload) {
            commit("setDriveSeriesFilter", payload);
        },
        cancelEdit({commit, dispatch}) {
            commit("resetEditIndex");
            commit("resetChangeStatusesForEditItem");
            dispatch("setItemDefaults");
        },
        setItemDefaults({commit, getters, rootState}) {
            let item = simpleClone(defaultItem);
            item.shortDescription = null;
            commit("setItem", {item});

            const pleat = simpleClone(getters.pleats ? getters.pleats[0] : []);

            commit("setItemPleat", pleat);

            commit(
                "setItemPleatGlobalAccessoryEntry",
                getters.itemPleatGlobalAccessoryEntries ? getters.itemPleatGlobalAccessoryEntries[0] : []
            );
            commit(
                "setItemPleatGlobalBalkEntry",
                getters.itemPleatGlobalBalkEntries ? getters.itemPleatGlobalBalkEntries[0] : []
            );
            commit(
                "setItemPleatGlobalRunnerEntry",
                getters.itemPleatGlobalRunnerEntries ? getters.itemPleatGlobalRunnerEntries[0] : []
            );

            if(getters.item.pleat) {
                commit(
                    "setItemPleatAdditionalString",
                    getters.itemPleatAdditionalStrings ? getters.itemPleatAdditionalStrings[0] : []
                );
            }
            commit(
                "setItemPleatPleatFixingTypeEntry",
                pleat.pleatPleatFixingTypeEntries ? pleat.pleatPleatFixingTypeEntries[0] : []
            );

            commit("setItemPleatGroup", getters.pleatGroups ? getters.pleatGroups[0] : []);

            if (
                getters.item.pleatGroup &&
                getters.item.pleatGroup.pleatFabrics.length > 0
            ) {
                commit("setItemFabric", getters.item.pleatGroup.pleatFabrics[0]);
                if (getters.item.pleatFabric.defaultPleatFabricColor !== null) {
                    commit("setItemFabricColor", getters.item.pleatFabric.defaultPleatFabricColor);
                }else{
                    commit("setItemFabricColor", getters.item.pleatFabric.pleatFabricColors[0]);
                }
            }

            if(getters.item.pleat.fabricCount == 2 ) {
                commit("setItemPleatGroupBottom", getters.pleatGroups ? getters.pleatGroups[0] : []);
                if (
                    getters.item.pleatGroup &&
                    getters.item.pleatGroup.pleatFabrics.length > 0
                ) {
                    commit("setItemFabricBottom", getters.item.pleatGroupBottom.pleatFabrics[0]);
                    if (getters.item.pleatFabricBottom.defaultPleatFabricColor) {
                        commit("setItemFabricColorBottom", getters.item.pleatFabricBottom.defaultPleatFabricColor);
                    }
                }
            }

                //jesli istnieja typy mocowan to jest ustaw
                if (
                    getters.item.pleat &&
                    getters.item.pleat.pleatPleatFixingTypeEntries.length > 0
                ) {
                    commit(
                        "setItemPleatPleatFixingTypeEntry",
                        getters.item.pleat.pleatPleatFixingTypeEntries[0]
                    );
                }
                //jesli istnieja typy sterowań to jest ustaw
                if (
                    getters.item.pleat &&
                    getters.item.pleat.pleatPleatRemoteControlTypeEntries.length > 0
                ) {
                    commit(
                        "setItemPleatPleatRemoteControlTypeEntry",
                        getters.item.pleat.pleatPleatRemoteControlTypeEntries[0]
                    );
                }
                //jeśi istnieja piloty dla napędu to ustaw pierwszy z napędu lub sterowania
                if (
                    getters.item.globalDrive &&
                    getters.item.globalDrive.globalRemoteControls.length > 0
                ) {
                    commit(
                        "setItemGlobalRemoteControl",
                        getters.item.globalDrive.globalRemoteControls[0]
                    );
                } else if (
                    getters.item.globalDriveControl &&
                    getters.item.globalDriveControl.globalRemoteControls.length > 0
                ) {
                    commit(
                        "setItemGlobalRemoteControl",
                        getters.item.globalDriveControl.globalRemoteControls[0]
                    );
                }
                if (rootState.company.companyInstallationValues.length > 0) {
                    const installationArray = objectToCollection(
                        rootState.company.companyInstallationValues
                    );

                    let array = installationArray.filter(
                        civ =>
                            civ.pleats &&
                            civ.pleats.findIndex(
                                fs => fs === getters.item.pleat["@id"]
                            ) > -1
                    );
                    if (array.length > 0) {
                        commit("setItemCompanyInstallationValue", array[0]);
                    }
            }
        },
        async saveCustomer({commit, state}, type = "create") {
            commit("startLoading");
            let error = {field: null, message: null};
            let isError = false;
            if (state.order.customer.type === "person") {
                if (!state.order.customer.lastName || state.order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!state.order.customer.city || state.order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (state.order.customer.type === "company") {
                if (
                    !state.order.customer.companyName ||
                    state.order.customer.companyName === "" ||
                    !state.order.customer.taxId ||
                    state.order.customer.taxId === "" ||
                    !state.order.customer.city ||
                    state.order.customer.city === ""
                ) {
                    if (
                        !state.order.customer.companyName ||
                        state.order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!state.order.customer.taxId || state.order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!state.order.customer.city || state.order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (state.order.customer.companyName && state.order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (state.order.customer.taxId && state.order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }
            if (type === "create" && state.order.customer.id) {
                delete state.order.customer.id;
                delete state.order.customer["@id"];
                delete state.order.customer["@type"];
            }
            let method = type === "create" ? "post" : "put";
            let url = type === "create" ? "/customers" : `/customers/${state.order.customer.id}`;
            await http[method](url, state.order.customer)
                .then(response => {
                    const data = response.data;
                    notify(t.t("customer_updated"));
                    commit("updateCustomer", data);
                })
                .catch(error => {
                    const resp = error.response;
                    if (resp) {
                        if (resp.status === 400) {
                            const violations = resp.data.violations;
                            violations.forEach(violation => {
                                notify(
                                    `${t.t("field")} ${t.t(violation.propertyPath)} ${t.t(
                                        violation.message
                                    )}`,
                                    "warning"
                                );
                            });
                        }
                    }
                });
            commit("endLoading");
        },
        async removeItem({commit, dispatch, state}, index) {
            commit("removeItem", index);
            commit("resetChangeStatusesForEditItem");
            if (state.editIndex === index) {
                dispatch("setItemDefaults");
            }
            await dispatch("updateOrder");
        },
        async removeAutomationItem({commit, dispatch, state}, index) {
            commit("removeAutomationItem", index);
            if (state.editIndex === index) {
                dispatch("setItemDefaults");
            }
            await dispatch("updateOrder");
        },
        async saveItemAndClose({dispatch}) {
            dispatch("saveItem").then(dispatch("valuateOrder"));
        },
        async saveAutomationItem({commit, dispatch}, item) {
            commit("addAutomationItem", item);
            notify("Dodano pozycję do zamówienia");

            await dispatch("updateOrder");
        },
        async saveItem({commit, dispatch, getters}) {
            if (getters.isEdit) {
                commit("saveItem");
                commit("resetEditIndex");
                commit("resetChangeStatusesForEditItem");
                // dispatch("setItemDefaults");
                notify("Zmieniono pozycję zamówienia");
            } else {
                commit("addItem");
            }
            await dispatch("updateOrder");
        },
        async clearOrder({state, dispatch, commit}) {
            let order = simpleClone(state.order);
            dispatch("updateClearOrder", {order})
            state.order.pleatOrderItems = [];
            state.order.automationOrderItems = [];

            state.order.netSalesValue = 0;
            state.order.salesGrossValue = 0;
            state.order.totalSalesNetValue = 0;
            state.order.leftToPayValue = 0;
            state.order.netSalesValueAfterDiscount = 0;
            state.order.percentDiscount = 0;
            state.order.withInstallation = false;
            state.order.installationValue = 0;
            state.order.installationValuePerItem = 0;
            state.order.fitterTravelCost = null;
            state.order.fitterTravelCostValue = 0;
            state.order.advanceValue = 0;
            state.order.discountValue = 0;
            state.order.packageNote = "";
            state.order.notes = "";

            commit("")
            commit("resetCustomer")
            commit("setExtraPackageNote", "");

            state.editIndex = null;
            dispatch("updateOrder");
        },
        updateClearOrder: async function (state, {order}) {
            const orderData = prepareOrderToSent(order);
            orderData.customer = null
            http.put(`/orders/${order.id}/clear`, orderData);
        },
        updateOrder: debounce(async function ({commit, getters, rootState}) {
            // commit("common/startLoading", null, {root: true})
            // commit("startLoading");
            let order = simpleClone(getters.order);
            let customer = simpleClone(getters.order.customer);
            if (!customer.id || !customer["@id"]) {
                order.customer = null;
            }
            order = prepareOrderToSent(order);

            if (!order.number) {
                order.number = order.id;
            }

            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }

            let {data} = await http.put(`/orders/${order.id}`, order);

            if (data.percentDiscount !== order.percentDiscount) {
                commit("addError", {
                    field: "percentDiscount",
                    message: "percent_discount_exceeded"
                });
            } else {
                commit("deleteError", "percentDiscount");
            }

            if (!customer.id || !customer["@id"]) {
                data.customer = customer;
            }
            commit("setOrder", data);
            // commit("endLoading");
            // commit("common/endLoading", null, {root: true})
        }, 1000),
        async placeOrder(
            {state, commit, dispatch, rootState, rootGetters},
            isEditing = false
        ) {
            if (state.order.pleatOrderItems.length === 0) {
                notify(t.t("order_cant_be_empty"), "warning");
                return;
            }

            commit("startLoading");
            let order = simpleClone(state.order);
            order = prepareOrderToSent(order);
            if (!order.number) {
                order.number = order.id;
            }

            let error = {field: null, message: null};
            let isError = false;
            if (order.customer.type === "person") {
                if (!order.customer.lastName || order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!order.customer.city || order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (order.customer.type === "company") {
                if (
                    !order.customer.companyName ||
                    order.customer.companyName === "" ||
                    !order.customer.taxId ||
                    order.customer.taxId === "" ||
                    !order.customer.city ||
                    order.customer.city === ""
                ) {
                    if (
                        !order.customer.companyName ||
                        order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.taxId || order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.city || order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (order.customer.companyName && order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (order.customer.taxId && order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }
            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }
            let note = order.packageNote || "";

            if (state.extraPackageNote) {
                if(order.packageNote)note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
                note = state.extraPackageNote +  "." + note;
            }

            order.packageNote = note;

            const {data} = await http.put(
                `/orders/${state.order.id}/complete`,
                order
            );
            commit("setExtraPackageNote", "");
            if (data.status === "new") {
                // redirect to order show
            }

            if (isEditing) {
                await router.push({name: "pleats_create_order"});
            } else {
                // asking api for draft order as on launch and set defaults values for item after placing order
                dispatch("getOrder");
                dispatch("setItemDefaults");
            }
            commit("endLoading");
            notify(t.t("new_complete_order_added"));

            notify(t.t("new_complete_order_added_thanks", {orderId: orderNumberCreator(order.id)}) + "<br>" +
                t.t("new_complete_order_added_price", {price: rootGetters["common/formatPrice"](order.totalPurchaseGrossValue)}) + "<br>" +
                t.t('new_complete_order_added_more'), "success", "is-top", 7000);
        },
        async valuateOrder(
            {state, commit, dispatch, rootState},
            isEditing = false
        ) {
            if (state.order.pleatOrderItems.length === 0) {
                notify(t.t("order_cant_be_empty"), "warning");
                return;
            }

            commit("startLoading");
            let order = simpleClone(state.order);
            order = prepareOrderToSent(order);

            if (!order.number) {
                order.number = order.id;
            }

            let error = {field: null, message: null};
            let isError = false;
            if (order.customer.type === "person") {
                if (!order.customer.lastName || order.customer.lastName === "") {
                    error.field = "lastName";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_last_name_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "lastName");
                }
                if (!order.customer.city || order.customer.city === "") {
                    error.field = "city";
                    error.message = "value_shouldnt_be_empty";
                    commit("addError", error);
                    notify(t.t("customer_city_is_empty"), "warning");
                    commit("endLoading");
                    isError = true;
                } else {
                    commit("deleteError", "city");
                }
                if (isError) {
                    return;
                }
            } else if (order.customer.type === "company") {
                if (
                    !order.customer.companyName ||
                    order.customer.companyName === "" ||
                    !order.customer.taxId ||
                    order.customer.taxId === "" ||
                    !order.customer.city ||
                    order.customer.city === ""
                ) {
                    if (
                        !order.customer.companyName ||
                        order.customer.companyName === ""
                    ) {
                        error.field = "companyName";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.taxId || order.customer.taxId === "") {
                        error.field = "taxId";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    if (!order.customer.city || order.customer.city === "") {
                        error.field = "city";
                        error.message = "value_shouldnt_be_empty";
                        commit("addError", error);
                    }
                    notify(t.t("customer_tax_or_company_name_or_city_is_empty"), "warning");
                    commit("endLoading");
                    return;
                } else {
                    if (order.customer.companyName && order.customer.companyName !== "") {
                        commit("deleteError", "companyName");
                    }
                    if (order.customer.taxId && order.customer.taxId !== "") {
                        commit("deleteError", "texId");
                    }
                }
            }

            if (rootState.company.buyType === "wholesale") {
                order.customer = null;
            }

            let note = order.packageNote || "";

            if (state.extraPackageNote) {
                if(order.packageNote)note = order.packageNote.substring(order.packageNote.indexOf(".") + 1);
                note = state.extraPackageNote +  "." + note;
            }

            order.packageNote = note;

            const {data} = await http.put(
                `/orders/${state.order.id}${isEditing ? "" : "/valuation"}`,
                order
            );
            commit("setExtraPackageNote", "");
            if (data.status === "new") {
                // redirect to order show
            }

            if (isEditing) {
                await router.push({name: "pleats_create_order"});
            } else {
                // asking api for draft order as on launch and set defaults values for item after placing order
                dispatch("getOrder");
                dispatch("setItemDefaults");
            }

            commit("endLoading");
            notify(isEditing ? t.t("valuation_saved") : t.t("new_valuation_added"));
        },
        async getOrderForCopy({commit, dispatch}, id) {
            try {
                const order = (await http.get(`/orders/${id}`)).data;

                //wait for all recalculate, so use it as promise
                await Promise.all(
                  order.pleatOrderItems.map(item => {
                      delete item.id;
                      return dispatch("recalculateOrderItem", item);
                  }))
                commit("setPleatOrderItems", order.pleatOrderItems);
                commit("setCustomer", order.customer);
                dispatch("updateOrder");


            } catch (e) {
                notify(t.t("order_copy_error", {id}), "error", "is-top", 7000);
            }
        },
        async recalculateOrderItem({state},item) {
            state.priceLoading = true;
            item = simpleClone(item);
            item = prepareItemToSent(item);
            delete item["@id"];
            delete item.id;
            await http.post(`/calculate/pleat_order_item`, item)
              .then(({data}) => {
                  item.netPurchaseValue = data.netPurchaseValue;
                  item.netSalesValue = data.netSalesValue;
              })
              .catch(error => {
                  notify(error.response.data["hydra:description"], "danger");
              });
        },
        async getOrder({commit, rootGetters}, id = null) {
            //check if id is set, if yes, its valuation edit mode
            let order = null;
            if (id !== null && !isNaN(id)) {
                let response = await http.get(`/orders/${id}`);
                order = response.data;
            } else {
                // find drafts
                let {data} = await http.get(
                    `/orders?${store.getters["auth/companyTypeForUrlProp"]}.id=${store.getters["auth/companyId"]}&status=draft&category=pleat&properties[]=id`
                );
                // get first draft by id as order
                if (data["hydra:member"].length > 0) {
                    let first = data["hydra:member"][0];
                    let response = await http.get(`/orders/${first.id}`);
                    order = response.data;
                } else {
                    let params = {
                        category: "pleat",
                        customer: null
                    };
                    // create new order
                    let response = await http.post("/orders", params);
                    order = response.data;
                }
                if (order.customer === null) {
                    order.customer = {
                        type: "person",
                        companyName: null,
                        firstName: null,
                        lastName: null,
                        zipCode: null,
                        city: null,
                        streetAndNumber: null,
                        phone: null,
                        email: null,
                        taxId: null
                    };
                }
                if (!order.name) {
                    order.name = `Wycena numer ${orderNumberCreator(order.id)}`;
                }
                if (
                    !order.companyVatRate &&
                    objectToCollection(rootGetters["company/companyVatRates"]).length > 0
                ) {
                    let first = null;
                    for (const rate in rootGetters["company/companyVatRates"]) {
                        if (first === null) {
                            first = rate;
                        }
                        if (
                            rootGetters["company/companyVatRates"][rate].name.includes(
                                "23"
                            ) ||
                            rootGetters["company/companyVatRates"][rate].value
                                .toString()
                                .includes("23")
                        ) {
                            order.companyVatRate =
                                rootGetters["company/companyVatRates"][rate]["@id"];
                            break;
                        }
                    }
                    if (!order.companyVatRate) {
                        order.companyVatRate =
                            rootGetters["company/companyVatRates"][first]["@id"];
                    }
                }

                if (
                    rootGetters["company/defaultDeliveryMethod"] &&
                    !order.deliveryMethod &&
                    objectToCollection(rootGetters["company/deliveryMethods"]).length > 0
                ) {
                    let first = null;
                    for (const delivery in rootGetters["company/deliveryMethods"]) {
                        if (first === null) {
                            first = delivery;
                        }
                        if (
                            rootGetters["company/deliveryMethods"][delivery]["@id"] ===
                            rootGetters["company/defaultDeliveryMethod"]
                        ) {
                            order.deliveryMethod =
                                rootGetters["company/deliveryMethods"][delivery];
                            break;
                        }
                    }
                    if (!order.deliveryMethod) {
                        order.deliveryMethod =
                            rootGetters["company/deliveryMethods"][first];
                    }
                }

                if (
                    !order.paymentMethod &&
                    objectToCollection(rootGetters["company/paymentMethods"]).length > 0
                ) {
                    for (const cost in rootGetters["company/paymentMethods"]) {
                        order.paymentMethod = rootGetters["company/paymentMethods"][cost];
                        break;
                    }
                }
            }
            commit("setOrder", order);
        },
        async init({commit, dispatch}) {
            // commit("startLoading");
            let [
                pleats,
                // pleatGlobalAccessoryEntries,
                // pleatGlobalBalkEntries,
                // pleatGlobalRunnerEntries,
                pleatGroups,
                // pleatGlobalFixingEntries
            ] = await Promise.all([
                http.get(
                    "/pleats?order[position]=ASC&" +
                    generateProperties(
                        [],
                        [
                            "id",
                            "name",
                            "picture",
                            "thumbnail",
                            "message",
                            "minWidth",
                            "maxWidth",
                            "minHeight",
                            "maxHeight",
                            "pleatWidth",
                            "fabricWidthOperator",
                            "surveyPicture",
                            "fabricCount",
                            "balkCount",
                            "mechanismPicture",
                            "previewType",
                            "isWithGlassSizes",
                            "measurementInstruction",
                            "surveyDescriptionPL",
                            "surveyDescriptionCZ",
                            "surveyDescriptionEN",
                            "surveyDescriptionDE",
                            "surveyDescriptionFR",
                            "surveyDescriptionSE",
                            "surveyDescriptionIT",
                            "surveyDescriptionSI",
                            "surveyDescriptionRU",
                            "surveyDescriptionES",
                            "surveyDescriptionNL",
                            "productionFabricHeight",
                            "pleatPleatRemoteControlTypeEntries",
                            "isRequiredAdditionalStrain"
                        ]
                    ) +
                    generateProperties(
                        ["pleatPleatFixingTypeEntries"],
                        ["isEnabled"],
                        true
                    ) +
                    generateProperties(
                        ["pleatPleatFixingTypeEntries", "pleatFixingType"],
                        ["id", "name", "picture", "message"],
                        true
                    ) +
                    generateProperties(
                        ["pleatPleatAdditionalEntries"],
                        ["isEnabled"],
                        true
                    ) +
                    generateProperties(
                        ["pleatPleatAdditionalEntries", "pleatAdditional"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        ["pleatGlobalRunnerEntries", "globalRunner"],
                        ["id", "name", "thumbnail", "picture", "message"],
                        true
                    ) +
                    generateProperties(
                        [
                            "pleatGlobalRunnerEntries",
                            "globalRunner",
                            "fabricShadeColors"
                        ],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        ["pleatGlobalAccessoryEntries"],
                        ["isEnabled"],
                        true
                    ) +
                    generateProperties(
                        ["pleatGlobalAccessoryEntries", "globalAccessory"],
                        ["id", "name", "picture", "thumbnail", "message"],
                        true
                    ) +
                    generateProperties(
                        [
                            "pleatGlobalAccessoryEntries",
                            "globalAccessory",
                            "fabricShadeColors"
                        ],
                        ["id", "name"],
                        true
                    ) +
                    generateProperties(
                        [
                            "pleatGlobalAccessoryEntries"
                        ],
                        ["id"],
                        true
                    ) +
                    generateProperties(
                        [
                            "pleatAdditionalStrings"
                        ],
                        ["id", "symbol", "isEnabled", "picture"],
                        true
                    ) +
                    generateProperties(
                        [
                            "pleatAdditionalStrings",
                            "globalRunner"
                        ],
                        ["id", "name", "thumbnail", "picture", "message"],
                        true
                    ) +
                    generateProperties(
                        ["globalPipes"],
                        ["id", "name"],
                        true)
                ),
                http.get("/pleat_groups"),
            ]);
            commit("setPleats", pleats.data["hydra:member"]);
            commit("setPleatGroups", pleatGroups.data["hydra:member"]);
            dispatch("setItemDefaults");
            // commit("endLoading");
        },
        async fetchGus({state, commit}) {
            const nip = state.order.customer.taxId
                .replace(/^\D+/g, "")
                .replaceAll("-", "");
            commit("startGusFetching");
            try {
                let {data} = await http.get(`/gus/nip/${nip}`);
                commit("setCustomerByGusApiData", data);
            } catch (e) {
                commit("endGusFetching");
                window.alert(t.t("gus_problem"));
            }
            commit("endGusFetching");
        },
        resetCustomer({commit}) {
            commit("resetCustomer");
        }
    }
};
