<template lang="pug">
  div
    .columns.is-desktop
      .column.is-10-desktop
        // Nowa pozycja zamówienia
        app-view-card(:title="$t(itemDataTitle)")
          b-notification(type="is-danger" v-if="globalError" :closable="false") {{ globalError }}
          form.external-shutter-form
            .columns.is-multiline
              .column(:class="{'is-one-quarter-desktop': !isSimpleForm}")
                b-field(grouped)
                  app-form-field(:label="$t('version')" field="externalShutter" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="externalShutters"
                        label="name"
                        v-model="item.externalShutter"
                        :clearable="false"
                        @input="onExternalShutterChanged"
                        :placeholder="$t('empty')"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutter, 'picture')"
                        @open="openImage($event)"
                      )

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(field="hasMosquitoNet" horizontal expanded)
                    b-field
                      b-checkbox(
                        v-model="item.externalShutterOrderItemCombinations[0].hasMosquitoNet"
                        :disabled="!itemHasMosquitoNet(0)"
                        @input="onHasMosquitoNetChange(0)"
                      ) {{ $t('external_shutter.has_mosquito_net') }}


                b-field(v-if="!loading" grouped)
                  app-form-field(:label="$t('external_shutter.external_box_color')" field="temporaryExternalBoxColor" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterBoxExternalFabricShadeColorEntries"
                        label="fabricShadeColor"
                        v-model="item.temporaryExternalBoxColor"
                        :clearable="false"
                        @input="onExternalShutterBoxExternalFabricShadeColorEntriesChanged"
                        :placeholder="$t('empty')"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                        template(v-slot:option='option')
                          p {{option.fabricShadeColor.name}}
                        template( v-slot:selected-option='option')
                          p {{option.fabricShadeColor.name}}

                b-field(v-if="!loading" grouped)
                  app-form-field(:label="$t('external_shutter.internal_box_color')" field="temporaryInternalBoxColor" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterBoxInternalFabricShadeColorEntries"
                        label="fabricShadeColor"
                        v-model="item.temporaryInternalBoxColor"
                        :clearable="false"
                        :placeholder="$t('empty')"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                        template(v-slot:option='option')
                          p {{option.fabricShadeColor.name}}
                        template( v-slot:selected-option='option')
                          p {{option.fabricShadeColor.name}}
                        templat(v-slot:no-options) $t('empty')



                b-field(grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_armor')" field="externalShutterPriceList" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterPriceLists"
                        label="name"
                        v-model="item.externalShutterPriceList"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="onExternalShutterPriceListChange(0)"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterArmor')){ return option.externalShutterArmor['name'] }} return option;}"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterPriceList, 'picture', 'externalShutterArmor')"
                        @open="openImage($event)"
                      )

                b-field(grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_armor_color')" field="externalShutterArmorExternalShutterArmorColorEntry" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterArmorExternalShutterArmorColorEntries"
                        label="name"
                        v-model="item.externalShutterArmorExternalShutterArmorColorEntry"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){if(option.hasOwnProperty('externalShutterArmorColor')){ return option.externalShutterArmorColor['name'] }} return option;}"
                        @input="calculatePrice"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterArmorExternalShutterArmorColorEntry, 'picture', 'externalShutterArmorColor')"
                        @open="openImage($event)"
                      )

                b-field(grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_armor_fabric_shade_color')" field="externalShutterArmorFabricShadeColorEntry" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterArmorFabricShadeColorEntries"
                        label="name"
                        v-model="item.externalShutterArmorFabricShadeColorEntry"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('fabricShadeColor')){ return option.fabricShadeColor['name'] }} return option;}"
                        @input="onExternalShutterExternalShutterRunnerEntryRightChange"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterArmorFabricShadeColorEntry, 'picture', 'fabricShadeColor')"
                        @open="openImage($event)"
                      )

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_left')" field="externalShutterExternalShutterRunnerEntryLeft" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterExternalShutterRunnerEntries(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterExternalShutterRunnerEntryLeft"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterRunner')){ return option.externalShutterRunner['name'] }} return option;}"
                        @input="onExternalShutterExternalShutterRunnerEntryLeftChange(0)"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterExternalShutterRunnerEntryLeft, 'picture', 'externalShutterRunner')"
                        @open="openImage($event)"
                      )

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_right')" field="externalShutterExternalShutterRunnerEntryRight" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterExternalShutterRunnerEntries(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterExternalShutterRunnerEntryRight"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterRunner')){ return option.externalShutterRunner['name'] }} return option;}"
                        @input="onExternalShutterExternalShutterRunnerEntryRightChange(0)"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterExternalShutterRunnerEntryRight, 'picture', 'externalShutterRunner')"
                        @open="openImage($event)"
                      )

                b-field(grouped)
                  app-form-field(:label="$t('external_shutter.runners_color')" field="externalShutterExternalShutterRunnerEntryRight" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterRunnerFabricShadeColorEntries"
                        label="name"
                        v-model="item.externalShutterRunnerFabricShadeColorEntry"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('fabricShadeColor')){ return option.fabricShadeColor['name'] }} return option;}"
                        @input="calculatePrice"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_left')" field="externalShutterExternalShutterRunnerEntryDrillHoleLeft" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterRunnerDrillHolesLeft(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterDrillHoleLeft"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="calculatePrice"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterDrillHoleLeft, 'picture')"
                        @open="openImage($event)"
                      )

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_right')" field="externalShutterExternalShutterRunnerEntryDrillHoleLeft" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterRunnerDrillHolesRight(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterDrillHoleRight"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="calculatePrice"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterDrillHoleRight, 'picture')"
                        @open="openImage($event)"
                      )



                b-field(v-if="isSimpleForm" group-multiline)
                  app-form-field(:label="$t('external_shutter.external_shutter_protections')" field="externalShutterExternalShutterProtectionsEntries" horizontal expanded)
                    b-field(
                      type="is-danger"
                      :message="errorByPath(0,'externalShutterExternalShutterProtectionEntries')"
                    )
                      v-select.has-pointer.w100(
                        :options="itemExternalShutterExternalShutterProtectionsEntries(0, true)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterExternalShutterProtectionEntries"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterProtection')){ return option.externalShutterProtection['name'] }} return option;}"
                        multiple
                        @input="onExternalShutterExternalShutterProtectionsEntriesChange(0)"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterOrderItemCombinations[0].externalShutterExternalShutterProtectionEntries.externalShutterProtection, 'picture')"
                        @open="openImage($event)"
                      )

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('external_shutter.external_shutter_adaptations')" field="itemExternalShutterAdaptations" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterAdaptations(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterAdaptation"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="onExternalShutterAdaptation"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('drive_type')" field="itemDriveType" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemDriveTypes(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].globalDriveType"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="onGlobalDriveTypeChanged(0)"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('drive_series')" field="itemDriveSeries" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemDriveSeries(0)"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].globalDriveSeries"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="onGlobalDriveSeriesChanged(0)"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}

                b-field(v-if="isSimpleForm" grouped)
                  app-form-field(:label="$t('drive_side')" field="itemDriveSeries" horizontal expanded)
                    b-field
                      v-select.w100.has-pointer(
                        :options="itemExternalShutterDriveExits()"
                        label="name"
                        v-model="item.externalShutterOrderItemCombinations[0].externalShutterDriveExit"
                        :clearable="false"
                        :placeholder="$t('empty')"
                        @input="calculatePrice"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                      app-form-hover-image(
                        :image="image(item.externalShutterOrderItemCombinations[0].externalShutterDriveExit, 'picture')"
                        @open="openImage($event)"
                      )
                app-form-field(:label="$t('installation_place')" field="shortDescription" horizontal expanded)
                  b-input(v-model="item.shortDescription" maxlength="20" :placeholder="$t('installation_place_example')")
                b-field(grouped)
                  app-form-field(
                    :label="$t('company_installation_value')"
                    field="companyInstallationValue"
                    horizontal
                    expanded
                  )
                    b-field
                      v-select.w100.has-pointer(
                        :options="companyInstallationValuesBySelectedExternalShutter"
                        label="name"
                        v-model="item.companyInstallationValue"
                        :clearable="false"
                        :placeholder="$t('empty')"
                      )
                        template(v-slot:no-options='') {{$t('empty')}}
                app-form-field(field="actions").has-text-right
                  b-button(
                    v-if="!isSimpleForm"
                    type="is-primary"
                    @click="combinationsSelectModal = true"
                  ) {{ $t('external_shutter.change_combination') }}

              .column(v-if="isSimpleForm").is-8
                .columns.is-multiline
                  .column.is-5
                    b-field(v-if="isSimpleForm" group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_width', { lengthUnit })"
                        field="externalShutterWidth"
                        :help="combinationWidthHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :warnings="warningsByCombination(0)"
                        :errors="errorsByCombination(0)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[0].width"
                          :unit="lengthUnit"
                          @blur="onExternalShutterCombinationWidthChange(0)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )



                    b-field(v-if="isSimpleForm" group-multiline)
                      app-form-field(:label="$t('external_shutter.global_pipe')" field="globalPipe" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemGlobalPipes(0)"
                            label="symbol"
                            v-model="item.externalShutterOrderItemCombinations[0].globalPipe"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onGlobalPipeChange(0)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                          app-form-hover-image(
                            :image="image(item.globalPipe, 'picture')"
                            @open="openImage($event)"
                          )

                    b-field(v-if="isSimpleForm" group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_runners_height', { lengthUnit })"
                        field="runnersHeight"
                        :help="runnersHeightHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :errors="errorsByCombination(0)"
                        :warnings="warningsByCombination(0)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[0].runnersHeight"
                          :unit="lengthUnit"
                          @blur="onExternalShutterCombinationRunnersHeightChange(0)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )

                    b-field(v-if="isSimpleForm" group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_full_height', { lengthUnit })"
                        field="fullHeight"
                        :help="fullHeightHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :errors="errorsByCombination(0)"
                        :warnings="warningsByCombination(0)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[0].fullHeight"
                          :unit="lengthUnit"
                          @blur="onExternalShutterCombinationFullHeightChange(0)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )

                    b-field(v-if="isSimpleForm" grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_box')" field="externalShutterBox" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterBoxes(0)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[0].externalShutterBox"
                            :clearable="false"
                            @input="onExternalShutterPriceListChange(0)"
                            :placeholder="$t('empty')"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                          app-form-hover-image(
                            :image="image(item.externalShutterOrderItemCombinations[0].externalShutterBox, 'picture',)"
                            @open="openImage($event)"
                          )

                    b-field(v-if="isSimpleForm" grouped)
                      app-form-field(field="saveExternalShutterBox" horizontal expanded)
                        b-field
                          b-checkbox(
                            v-model="item.externalShutterOrderItemCombinations[0].tempExternalShutterBoxForAllOrderFlag"
                            :disabled="!item.externalShutterOrderItemCombinations[0].externalShutterBox"
                            @input="saveExternalShutterBox(0)"
                          )  {{ $t('external_shutter.saveExternalShutterBox') }}



                    app-form-field(:label="$t('order.pieces')" field="quantity" horizontal expanded)
                      b-numberinput(v-model="item.quantity" min="1" max="100" @input="onQuantityChange")

                    app-form-field(field="actions").has-text-right
                      b-button(
                        v-if="!isSimpleForm"
                        type="is-primary"
                        @click="combinationsSelectModal = true"
                      ) {{ $t('external_shutter.change_combination') }}

                      b-button(
                        v-if="isSimpleForm"
                        type="is-primary"
                        @click="combinationsSelectModal = true"
                      ) {{ $t('external_shutter.add_combination') }}

                  .column.is-full-mobile.is-full-tablet.is-two-thirds-desktop.is-two-thirds-widescreen.is-half-fullhd.is-offset-1
                    external-shutter-order-item-preview-window( v-if="!getHideImage" :orderItem="item", :combinationImage="singleCombinationPreview")

              .column.is-7
                .columns(v-if="!isSimpleForm").is-multiline.is-centered
                  .column(v-if="item.selectedCombination").is-12
                    .columns.is-centered
                      .column.is-5
                        p.has-text-centered.is-size-5.mb-3 {{$t('external_shutter.preview_from_inside')}}
                        img(:src="combinationUrlFormatter(combinationImage)").combination-image
                        p.has-text-centered.is-size-5.mt-3 {{item.selectedCombination}}
                      .column.is-2.is-vcentered {{$t('external_shutter.combination_full_height', { lengthUnit }) }}: {{ itemExternalShutterFullHeight }} <br> {{$t('external_shutter.combination_full_width',{ lengthUnit }) }}: {{ itemExternalShutterFullWidth }}

                  .column(v-for="(combination, idx) in item.externalShutterOrderItemCombinations")

                    b-field(grouped)
                      app-form-field(field="hasMosquitoNet" customClass="is-size-4" :label="(idx+1).toString()" horizontal expanded)
                        b-field.mt-5
                          b-checkbox(
                            v-model="item.externalShutterOrderItemCombinations[idx].hasMosquitoNet"
                            :disabled="!itemHasMosquitoNet(0)"
                            @input="onHasMosquitoNetChange(idx)"
                          ) {{ $t('external_shutter.has_mosquito_net') }}

                    b-field(group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_width', { lengthUnit })"
                        field="externalShutterWidth"
                        :help="combinationWidthHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :errors="errorsByCombination(idx)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[idx].width"
                          :unit="lengthUnit"
                          @blur="onExternalShutterCombinationWidthChange(idx)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.global_pipe')" field="globalPipe" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemGlobalPipes(idx)"
                            label="symbol"
                            v-model="item.externalShutterOrderItemCombinations[idx].globalPipe"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onGlobalPipeChange(idx)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}

                    b-field(group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_runners_height', { lengthUnit })"
                        field="runnersHeight"
                        :help="runnersHeightHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :errors="errorsByCombination(idx)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[idx].runnersHeight"
                          :unit="lengthUnit"
                          :disabled="checkCombinationDisabled(idx)"
                          @input="onExternalShutterCombinationRunnersHeightChange(idx)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )

                    b-field(group-multiline)
                      app-form-field(
                        :label="$t('external_shutter.combination_full_height', { lengthUnit })"
                        field="fullHeight"
                        :help="fullHeightHelp"
                        :showHelp="showHelpForOrderDimensions"
                        :errors="errorsByCombination(idx)"
                        horizontal
                        expanded
                      )
                        app-form-length(
                          v-model="item.externalShutterOrderItemCombinations[idx].fullHeight"
                          :unit="lengthUnit"
                          :disabled="checkCombinationDisabled(idx)"
                          @input="onExternalShutterCombinationFullHeightChange(idx)"
                          @focus="showHelpForOrderDimensions = true"
                          customClass="pl-1"
                        )

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_box')" field="externalShutterBox" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterBoxes(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterBox"
                            :clearable="false"
                            :disabled="checkCombinationDisabled(idx)"
                            @input="onExternalShutterPriceListChange(idx)"
                            :placeholder="$t('empty')"
                          )
                          template(v-slot:no-options='') {{$t('empty')}}
                        app-form-hover-image(
                          :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterBox, 'sectionPicture',)"
                          @open="openImage($event)"
                        )

                    b-field(grouped)
                      app-form-field(field="saveExternalShutterBox" horizontal expanded)
                        b-field
                          b-checkbox(
                            v-model="item.externalShutterOrderItemCombinations[idx].tempExternalShutterBoxForAllOrderFlag"
                            :disabled="!item.externalShutterOrderItemCombinations[idx].externalShutterBox"
                            @input="saveExternalShutterBox(idx)"
                          )  {{ $t('external_shutter.saveExternalShutterBox') }}

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_left')" field="externalShutterExternalShutterRunnerEntryLeft" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterExternalShutterRunnerEntries(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryLeft"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterRunner')){ return option.externalShutterRunner['name'] }} return option;}"
                            @input="onExternalShutterExternalShutterRunnerEntryLeftChange(idx)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                          app-form-hover-image(
                            :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryLeft, 'sectionLeftPicture', 'externalShutterRunner')"
                            @open="openImage($event)"
                          )

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_right')" field="externalShutterExternalShutterRunnerEntryRight" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterExternalShutterRunnerEntries(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryRight"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterRunner')){ return option.externalShutterRunner['name'] }} return option;}"
                            @input="onExternalShutterExternalShutterRunnerEntryRightChange(idx)"
                          )
                          app-form-hover-image(
                            :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryRight, 'sectionRightPicture', 'externalShutterRunner')"
                            @open="openImage($event)"
                          )

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_left')" field="externalShutterExternalShutterRunnerEntryDrillHoleLeft" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterRunnerDrillHolesLeft(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterDrillHoleLeft"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="calculatePrice"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                          app-form-hover-image(
                            :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterDrillHoleLeft, 'sectionLeftPicture')"
                            @open="openImage($event)"
                          )

                    b-field(grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_external_shutter_runner_entry_drill_hole_right')" field="externalShutterExternalShutterRunnerEntryDrillHoleLeft" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterRunnerDrillHolesRight(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterDrillHoleRight"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="calculatePrice"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                          app-form-hover-image(
                            :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterDrillHoleRight, 'sectionRightPicture')"
                            @open="openImage($event)"
                          )

                    b-field(group-multiline)
                      app-form-field(:label="$t('external_shutter.external_shutter_protections')" field="externalShutterExternalShutterProtectionsEntries" horizontal expanded)
                        b-field(
                          type="is-danger"
                          :message="errorByPath(idx,'externalShutterExternalShutterProtectionEntries')"
                        )
                          v-select.has-pointer.w100(
                            :options="itemExternalShutterExternalShutterProtectionsEntries(idx, true)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterProtectionEntries"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onExternalShutterExternalShutterProtectionsEntriesChange(idx)"
                            :getOptionLabel="option => {if (typeof option === 'object'){ if(option.hasOwnProperty('externalShutterProtection')){ return option.externalShutterProtection['name'] }} return option;}"
                            multiple
                          )
                            template(v-slot:no-options='') {{$t('empty')}}

                    b-field(v-if="!isSimpleForm" grouped)
                      app-form-field(:label="$t('external_shutter.external_shutter_adaptations')" field="itemExternalShutterAdaptations" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterAdaptations(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterAdaptation"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onExternalShutterAdaptation"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}


                    b-field(v-if="!isSimpleForm" grouped)
                      app-form-field(:label="$t('drive_type')" field="globalDriveType" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemDriveTypes(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].globalDriveType"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onGlobalDriveTypeChanged(idx)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                    b-field(v-if="!isSimpleForm" grouped)
                      app-form-field(:label="$t('drive_series')" field="itemDriveSeries" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemDriveSeries(idx)"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].globalDriveSeries"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onGlobalDriveSeriesChanged(idx)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                    b-field(v-if="!isSimpleForm" grouped)
                      app-form-field(:label="$t('drive_side')" field="itemDriveSeries" horizontal expanded)
                        b-field
                          v-select.w100.has-pointer(
                            :options="itemExternalShutterDriveExits()"
                            label="name"
                            v-model="item.externalShutterOrderItemCombinations[idx].externalShutterDriveExit"
                            :clearable="false"
                            :placeholder="$t('empty')"
                            @input="onGlobalDriveSeriesChanged(idx)"
                          )
                            template(v-slot:no-options='') {{$t('empty')}}
                        app-form-hover-image(
                          :image="image(item.externalShutterOrderItemCombinations[idx].externalShutterDriveExit, 'picture')"
                          @open="openImage($event)"
                        )
          br
          .columns(v-if="message")
            .column
            .column.is-four-fifths
              .border
                .has-font-size-15.communicate(:class="{'communicate-animation': isMessageBorderAnimating}") {{ message }}
          br
          table.table.is-fullwidth.is-bordered.is-narrow
            b-loading(:active.sync="priceLoading")
            thead
              tr
                th.has-text-centered.has-border-radius-top(colspan=4) {{ $t('order.total_net_value') }}
              tr
                th.has-text-centered {{ $t('order.surface', {surfaceUnit: "m&#178;"}) }}
                th.has-text-centered {{ $t('order.weight') }}
                th.has-text-centered(v-if="!hideWholeSalePrice") {{ $t('order.purchase') }}
                th.has-text-centered {{ $t('order.sale') }}
            tbody
              tr
                td.has-text-right {{ formatSurface(item.sumSurfacesAllCombinations * item.quantity)  }}
                td.has-text-right {{ formatWeight(item.weight) }}
                td.has-text-right(v-if="!hideWholeSalePrice") {{ formatPriceValue(formatPrice(item.netPurchaseValue)) }}
                td.has-text-right {{ formatPriceValue(formatPrice(item.netSalesValue)) }}
          b-field
            .buttons
              b-button(
                native-type="submit"
                type="is-primary"
                :loading="submitting"
                @click="saveItemInOrder"
                :disabled="!hasPermission('ORDER_UPDATE') || cantSave"
              ) {{ $t(addButtonLabel) }}
              //toggle show form
              b-button(type="is-warning" @click="toggleShowForm") {{ $t(`${showForm?'hide':'show'}_form`) }}
              b-button(v-if="isEdit" :loading="submitting" @click.prevent="cancelEdit") {{ $t('cancel') }}
              b-button(type="is-danger"  v-if="orderHasAtLeastOneItem" @click.prevent="clearValuation") {{ $t('clear_order') }}
              b-button(@click.prevent="refreshOrder") {{ $t('refresh') }}
              b-button(type="is-primary" @click="automationFormModal = true") {{ $t('automation.title_choose_automation') }}
      .column
        .columns.is-multiline.is-mobile
          .column.is-12-desktop.is-12-tablet
            .columns
              .column
                app-view-card(:title="$t('survey')" class="has-margin-bottom")
                  figure
                    .level
                      .level-item
                        img.has-pointer(
                          v-if="image(item.externalShutter, 'surveyPicture')"
                          :src="image(item.externalShutter, 'surveyPicture')"
                          width="100"
                          @click="openImage(image(item.externalShutter, 'surveyPicture'), item.externalShutter['surveyDescription'+getLocale])"
                        )
          .column.is-12-desktop.is-12-tablet
            .columns
              .column
                app-view-card(:title="$t('files')")
                  a(
                    v-if="item.externalShutter && item.externalShutter.measurementInstruction"
                    class="button is-primary"
                    :href="item.externalShutter.measurementInstruction.contentUrl"
                    target="_blank"
                    download
                  ) {{ $t('measurementInstruction') }}
    br
    b-modal(v-model="imageModal")
      .columns(style="width:99%").has-text-centered
        img(:src="imageForModal")
        .columns(v-if="centeredTextForModal")
          .column
          .column.is-half.has-text-centered
            .has-background-white.has-text-black(v-html="centeredTextForModal")
          .column
    b-modal(v-model="combinationsSelectModal")
      app-card(style="width:99%" :title="$t('external_shutter.combination_decision')")
        .columns
          .column.is-12.has-text-centered {{ $t('external_shutter.choose_combination') }}
        .columns
          .column
            div(v-for="file in getExternalShutterGraphics(1,2)" @click="onCombinationChanged(file)")
              img(:src="file.file" width="80%" ).has-pointer.combination-image.pb-4
          .column
            div(v-for="file in getExternalShutterGraphics(2,2)" @click="onCombinationChanged(file)")
              img(:src="file.file" width="90%").has-pointer.combination-image.pb-4.mb-5.mt-3
    automation-form-modal( productType="externalShutters" :globalDriveControls="itemGlobalDriveControls()" :automationModalShow="automationFormModal" @close="automationFormModal=false" )
</template>

<script>
import http from "../../../../http";
import {mapActions, mapGetters, mapMutations} from "vuex";
import notify from "../../../../helpers/notify";
import simpleClone from "../../../../helpers/simpleClone";
import ResourceSelect from "@/modules/common/components/ResourceSelect";
import prepareItemToSent from "@/helpers/prepareItemToSent";
import ExternalShutterOrderItemPreviewWindow from "./ExternalShutterOrderItemPreviewWindow"
import priceFormatter from "../../../../helpers/priceFormatter";
import localStorageFuncs from "../../../../helpers/localStorageHelper";
import AutomationFormModal from "../../../automation/components/AutomationFormModal";
import checkKeyInDeepObject from "../../../../helpers/checkKeyInDeepObject";
import objectToCollection from "../../../../helpers/objectToCollection";

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
        args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export default {
  components: {AutomationFormModal, ResourceSelect, ExternalShutterOrderItemPreviewWindow},
  data() {
    return {
      globalError: null,
      errors: [],
      warnings: [],
      submitting: false,
      showWidthError: false,
      priceLoading: false,
      imageModal: false,
      combinationsSelectModal: false,
      automationFormModal: false,
      imageForModal: "",
      centeredTextForModal: null,
      hoverImage: null,
      backgroundColor: "#E6E6E6",
      windowFrame: null,
      showHelpForOrderDimensions: false,
      globalOrderRemoteControlIsPosting: false,
      isCalculated: false,
      minValue: 300,
      singleCombinationPreview: '/external_shutter_combinations/single_combination_preview.jpg'
    };
  },
  methods: {
    ...mapActions("externalShutters", [
      "setMessage",
      "toggleShowForm",
      "setIsMessageBorderAnimating",
      "saveItem",
      "cancelEdit",
      "saveItemAndClose",
      "updateOrder",
      "clearOrder",
      "setItemDefaults",
      "setCombinationImage"
    ]),
    ...mapMutations("externalShutters", ["setItem", "increaseCombinationsInItem", "decreaseCombinationsInItem", "setItemGlobalDriveSeries"]),

    saveItemInOrder() {
      this.item.externalShutterOrderItemCombinations.forEach((item, index) => {
        this.addWarning('fullHeight', "", index)
        this.addWarning('externalShutterWidth', "", index)
        this.addWarning('runnersHeight', "tt", index)
      })

      this.saveItem()
    },

    clearValuation() {
      return this.$buefy.dialog.confirm({
        message: this.$t("remove_valuation_confirm"),
        onConfirm: () => {
          this.clearOrder();
        }
      });
    },

    refreshOrder() {
      this.calculatePrice()
    },

    combinationUrlFormatter(combinationName) {
      return "/external_shutter_combinations/K_" + combinationName + ".jpg"
    },

    onExternalShutterChanged() {

      this.removeError(null, null, true) // remove all error

      if (!checkKeyInDeepObject(this.itemExternalShutterPriceLists, '@id', this.item.externalShutterPriceList, 'externalShutterArmor')) {
        this.item.externalShutterPriceList = this.itemExternalShutterPriceLists[0] || null;
      }

      if (!checkKeyInDeepObject(this.itemExternalShutterBoxInternalFabricShadeColorEntries, '@id', this.item.temporaryInternalBoxColor, 'fabricShadeColor')) {
        this.item.temporaryInternalBoxColor = this.itemExternalShutterBoxInternalFabricShadeColorEntries[0] || null;
      }

      if (!checkKeyInDeepObject(this.itemExternalShutterBoxExternalFabricShadeColorEntries, '@id', this.item.temporaryExternalBoxColor, "fabricShadeColor")) {
        this.item.temporaryExternalBoxColor = this.itemExternalShutterBoxExternalFabricShadeColorEntries[0] || null;
      }

      if (!checkKeyInDeepObject(this.itemExternalShutterRunnerFabricShadeColorEntries, '@id', this.item.externalShutterRunnerFabricShadeColorEntry, 'fabricShadeColor')) {
        this.item.externalShutterRunnerFabricShadeColorEntry = this.itemExternalShutterRunnerFabricShadeColorEntries[0] || null
      }

      // per combination
      this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
        this.onExternalShutterBoxAutocomplete(idx)
        if (!this.itemHasMosquitoNet(idx)) combination.hasMosquitoNet = false;

        if (!checkKeyInDeepObject(this.itemExternalShutterDriveExits(), '@id', combination.externalShutterDriveExit)) {
          combination.externalShutterDriveExit = this.itemExternalShutterDriveExits()[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterExternalShutterRunnerEntries(idx), '@id', combination.externalShutterExternalShutterRunnerEntryLeft, 'externalShutterRunner')) {
          combination.externalShutterExternalShutterRunnerEntryLeft = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterExternalShutterRunnerEntries(idx), '@id', combination.externalShutterExternalShutterRunnerEntryRight, 'externalShutterRunner')) {
          combination.externalShutterExternalShutterRunnerEntryRight = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterRunnerDrillHolesLeft(idx), '@id', combination.externalShutterDrillHoleLeft)) {
          combination.externalShutterDrillHoleLeft = this.itemExternalShutterRunnerDrillHolesLeft(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterRunnerDrillHolesRight(idx), '@id', combination.externalShutterDrillHoleRight)) {
          combination.externalShutterDrillHoleRight = this.itemExternalShutterRunnerDrillHolesRight(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterAdaptations(idx), '@id', combination.externalShutterAdaptation)) {
          combination.externalShutterAdaptation = this.itemExternalShutterAdaptations(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemExternalShutterBoxes(idx), '@id', combination.externalShutterBox)) {
          combination.externalShutterBox = this.itemExternalShutterBoxes(idx)[0] || null;
        }

        if (!checkKeyInDeepObject(this.itemGlobalPipes(idx), '@id', combination.globalPipe)) {
          combination.globalPipe = this.itemGlobalPipes(idx)[0] || null;
        }
        if (!checkKeyInDeepObject(this.itemGlobalPipes(idx), '@id', combination.globalPipe)) {
          combination.globalPipe = this.itemGlobalPipes(idx)[0] || null;
        }

        combination.externalShutterExternalShutterProtectionEntries = checkKeyInDeepObject(this.itemExternalShutterExternalShutterProtectionsEntries(idx), '@id', combination.externalShutterExternalShutterProtectionEntries, 'externalShutterProtection')

      });

      this.onHasMosquitoNetChange();
      this.onExternalShutterPriceListChange();

    },


    onExternalShutterBoxExternalFabricShadeColorEntriesChanged() {

    },

    cloneFromToCombination(from = null, to = []) {
      to.forEach(id => {
        this.item.externalShutterOrderItemCombinations[id].runnersHeight = this.item.externalShutterOrderItemCombinations[from].runnersHeight
        this.item.externalShutterOrderItemCombinations[id].fullHeight = this.item.externalShutterOrderItemCombinations[from].fullHeight
        this.item.externalShutterOrderItemCombinations[id].externalShutterBox = simpleClone(this.item.externalShutterOrderItemCombinations[from].externalShutterBox)
      })

    },

    cloneCombinationValueByCombinationType() {

      const combinationName = this.item.selectedCombination
      const combinationNamesArray = this.externalShutterCombinationsGraphics.map(x => x.text)
      const firstCombination = 0;
      const secondCombination = 1;
      const thirdCombination = 2;

      switch (combinationName) {
        case combinationNamesArray[1] :
          this.cloneFromToCombination(firstCombination, [secondCombination]);
          break;
        case combinationNamesArray[4] :
          this.cloneFromToCombination(firstCombination, [secondCombination]);
          break;
        case combinationNamesArray[5] :
          this.cloneFromToCombination(firstCombination, [secondCombination]);
          break;
        case combinationNamesArray[6] :
          this.cloneFromToCombination(firstCombination, [secondCombination, thirdCombination]);
          break;
        case combinationNamesArray[7] :
          this.cloneFromToCombination(secondCombination, [thirdCombination]);
          break;
        case combinationNamesArray[8] :
          this.cloneFromToCombination(firstCombination, [thirdCombination]);
          break;
        case combinationNamesArray[9] :
          this.cloneFromToCombination(secondCombination, [thirdCombination]);
          break;
      }

    },

    checkCombinationDisabled(id) {
      const combinationName = this.item.selectedCombination

      const combinationNamesArray = this.externalShutterCombinationsGraphics.map(x => x.text)

      switch (combinationName) {
        case combinationNamesArray[1] :
          return id === 1
        case combinationNamesArray[4] :
          return id === 1
        case combinationNamesArray[5] :
          return id === 1
        case combinationNamesArray[6] :
          return (id === 1 || id === 2)
        case combinationNamesArray[7] :
          return id === 2
        case combinationNamesArray[8] :
          return id === 2
        case combinationNamesArray[9] :
          return id === 2
      }
    },


    async onExternalShutterCombinationWidthChange(id) {

      if (this.isCombinationWidthValid(id)) {

        if (!this.itemExternalShutterBoxes(id)) this.item.externalShutterBox = null;

        this.checkDrive(id); // check drive by width

        this.checkGlobalPipe(id);

        this.removeWarning('externalShutterWidth', id)

        this.calculatePrice();
      }
    },

    onRunnersEntryAutoComplete(idx) {
      if (idx != null) {
        if (!this.itemExternalShutterExternalShutterRunnerEntries(idx).some(item => item === this.item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryLeft)) {
          this.item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryLeft = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0]
        }

        if (!this.itemExternalShutterExternalShutterRunnerEntries(idx).some(item => item === this.item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryRight)) {
          this.item.externalShutterOrderItemCombinations[idx].externalShutterExternalShutterRunnerEntryRight = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0]
        }
      }
    },

    async onExternalShutterBoxAutocomplete(id, typeHeight = null) {

      const externalShutterBoxToAutocomplete = simpleClone(await this.itemExternalShutterBoxToAutocomplete(id, typeHeight))

      if (!externalShutterBoxToAutocomplete) return false

      this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
        if (!this.itemExternalShutterBoxes(idx).some(item =>  item=== this.item.externalShutterOrderItemCombinations[idx].externalShutterBox)) {
          this.item.externalShutterOrderItemCombinations[idx].externalShutterBox = externalShutterBoxToAutocomplete
        }

      if (typeHeight === 'runners' &&
          this.item.externalShutterOrderItemCombinations[idx].runnersHeight + this.item.externalShutterOrderItemCombinations[idx].externalShutterBox.height > 300) this.item.externalShutterOrderItemCombinations[idx].fullHeight = this.item.externalShutterOrderItemCombinations[idx].runnersHeight + this.item.externalShutterOrderItemCombinations[idx].externalShutterBox.height
      if (typeHeight === 'full' &&
          this.item.externalShutterOrderItemCombinations[idx].fullHeight - this.item.externalShutterOrderItemCombinations[idx].externalShutterBox.height > 300) this.item.externalShutterOrderItemCombinations[idx].runnersHeight = this.item.externalShutterOrderItemCombinations[idx].fullHeight - this.item.externalShutterOrderItemCombinations[idx].externalShutterBox.height

      })
      await this.calculatePrice();
    },

    async onExternalShutterCombinationRunnersHeightChange(id) {
      if (this.isRunnersHeightValid(id)) {
        await this.onExternalShutterBoxAutocomplete(id, 'runners')
        this.cloneCombinationValueByCombinationType()
      }
      this.removeWarning("runnersHeight", id, true)
    },

    async onExternalShutterCombinationFullHeightChange(id) {
      if (this.isFullHeightValid(id)) {
        await this.onExternalShutterBoxAutocomplete(id, 'full')
        this.cloneCombinationValueByCombinationType()
      }
      this.removeWarning("fullHeight", id, true)
    },

    async onGlobalPipeChange(id) {
      await this.onExternalShutterCombinationFullHeightChange(id)
      await this.onExternalShutterCombinationRunnersHeightChange(id)
      await this.onGlobalDriveSeriesChanged(id)
    },

    // save favorite ExternalShutterBox in localStorage
    saveExternalShutterBox(id) {
      if (this.item.externalShutterOrderItemCombinations[id].tempExternalShutterBoxForAllOrderFlag) {
        localStorageFuncs.localStorageSave("externalShutterBox", this.item.externalShutterOrderItemCombinations[id].externalShutterBox)
      } else {
        localStorageFuncs.localStorageRemove("externalShutterBox")
      }
    },
    onHasMosquitoNetChange(combinationIdx = null) {
      /* if (combinationIdx === null || isNaN(combinationIdx)) {
         this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
           combination.externalShutterExternalShutterRunnerEntryLeft = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0] || null;
           combination.externalShutterExternalShutterRunnerEntryRight = this.itemExternalShutterExternalShutterRunnerEntries(idx)[0] || null;
         });
       } else {
         this.item.externalShutterOrderItemCombinations[combinationIdx].externalShutterExternalShutterRunnerEntryLeft = this.itemExternalShutterExternalShutterRunnerEntries(combinationIdx)[0] || null;
         this.item.externalShutterOrderItemCombinations[combinationIdx].externalShutterExternalShutterRunnerEntryRight = this.itemExternalShutterExternalShutterRunnerEntries(combinationIdx)[0] || null;
       }
       this.item.externalShutterRunnerFabricShadeColorEntry = this.itemExternalShutterRunnerFabricShadeColorEntries[0] || null;
       this.onLeftAndRightExternalShutterRunnerEntryChange(combinationIdx);*/

      this.onRunnersEntryAutoComplete(combinationIdx)
      this.onExternalShutterBoxAutocomplete(combinationIdx)
      this.onExternalShutterAdaptation()
    },
    onExternalShutterPriceListChange(id = null) {
      if (!checkKeyInDeepObject(this.itemExternalShutterArmorExternalShutterArmorColorEntries, '@id', this.item.externalShutterArmorExternalShutterArmorColorEntry, ['externalShutterArmor, externalShutterArmorExternalShutterArmorColorEntries'])) {
        this.item.externalShutterArmorExternalShutterArmorColorEntry = this.itemExternalShutterArmorExternalShutterArmorColorEntries[0] || null;
      }
      if (!checkKeyInDeepObject(this.itemExternalShutterArmorFabricShadeColorEntries, '@id', this.item.externalShutterArmorFabricShadeColorEntry, ['externalShutterArmor, externalShutterArmorExternalShutterArmorColorEntries'])) {
        this.item.externalShutterArmorFabricShadeColorEntry = this.itemExternalShutterArmorFabricShadeColorEntries[0] || null;
      }

      if (id != null) {
        this.onGlobalPipeChange(id)
        this.onExternalShutterBoxAutocomplete(id)
      }

      this.onExternalShutterAdaptation()

      this.calculatePrice();

    },
    onExternalShutterExternalShutterRunnerEntryLeftChange(combinationIdx = null) {
      if (isNaN(combinationIdx) || combinationIdx === null) {
        this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
          if (!checkKeyInDeepObject(this.itemExternalShutterRunnerDrillHolesLeft(idx), '@id', combination.externalShutterDrillHoleLeft)) {
            combination.externalShutterDrillHoleLeft = this.itemExternalShutterRunnerDrillHolesLeft(idx)[0] || null;
          }
        });
      } else {
        if (!checkKeyInDeepObject(this.itemExternalShutterRunnerDrillHolesLeft(combinationIdx), '@id', this.item.externalShutterOrderItemCombinations[combinationIdx].externalShutterDrillHoleLeft)) {
          this.item.externalShutterOrderItemCombinations[combinationIdx].externalShutterDrillHoleLeft = this.itemExternalShutterRunnerDrillHolesLeft(combinationIdx)[0] || null;
        }
      }

      if (!checkKeyInDeepObject(this.itemExternalShutterRunnerFabricShadeColorEntries, '@id', this.item.externalShutterRunnerFabricShadeColorEntry, 'fabricShadeColor')) {
        this.item.externalShutterRunnerFabricShadeColorEntry = this.itemExternalShutterRunnerFabricShadeColorEntries[0] || null
      }

      this.calculatePrice();
    },
    onExternalShutterExternalShutterRunnerEntryRightChange(combinationIdx = null) {
      if (isNaN(combinationIdx) || combinationIdx === null) {
        this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
          if (!checkKeyInDeepObject(this.itemExternalShutterRunnerDrillHolesRight(idx), '@id', combination.externalShutterDrillHoleRight)) {
            combination.externalShutterDrillHoleRight = this.itemExternalShutterRunnerDrillHolesRight(idx)[0] || null;
          }
        });
      } else {
        this.item.externalShutterOrderItemCombinations[combinationIdx].externalShutterDrillHoleRight = this.itemExternalShutterRunnerDrillHolesRight(combinationIdx)[0] || null;
      }

      if (!checkKeyInDeepObject(this.itemExternalShutterRunnerFabricShadeColorEntries, '@id', this.item.externalShutterRunnerFabricShadeColorEntry, 'fabricShadeColor')) {
        this.item.externalShutterRunnerFabricShadeColorEntry = this.itemExternalShutterRunnerFabricShadeColorEntries[0] || null
      }

      this.calculatePrice();
    },
    onLeftAndRightExternalShutterRunnerEntryChange(combinationIdx = null) {
      this.onExternalShutterExternalShutterRunnerEntryLeftChange(combinationIdx);
      this.onExternalShutterExternalShutterRunnerEntryRightChange(combinationIdx);
    },

    onExternalShutterAdaptation() {
      this.item.externalShutterOrderItemCombinations.forEach((combination, idx) => {
        if (!checkKeyInDeepObject(this.itemExternalShutterAdaptations(idx), '@id', combination.externalShutterAdaptation)) {
          this.item.externalShutterOrderItemCombinations[idx].externalShutterAdaptation = this.itemExternalShutterAdaptations(idx)[0] || null;
        }
      });
      this.calculatePrice()
    },

    formatPriceValue(value) {
      return priceFormatter(value);
    },

    checkDrive(id) {
      if (
          (this.item.externalShutterOrderItemCombinations[id].globalDriveType &&
              this.itemDriveTypes(id).findIndex(
                  type => type.id === this.item.externalShutterOrderItemCombinations[id].globalDriveType.id
              ) < 0) ||
          (this.item.externalShutterOrderItemCombinations[id].globalDriveSeries &&
              this.itemDriveSeries(id).findIndex(
                  series => series.id === this.item.externalShutterOrderItemCombinations[id].globalDriveSeries.id
              ) < 0) ||
          (this.item.externalShutterOrderItemCombinations[id].globalDrive &&
              this.itemGlobalDrives(id).findIndex(
                  drive => drive.id === this.item.externalShutterOrderItemCombinations[id].globalDrive.id
              ) < 0)
      ) {
        // this.item.externalShutterOrderItemCombinations[id].globalDriveType = null;
        // this.item.externalShutterOrderItemCombinations[id].globalDriveSeries = null;
        // this.item.externalShutterOrderItemCombinations[id].globalDrive = null;

      } else {
        // this.item.externalShutterOrderItemCombinations[id].globalDriveType = this.item.externalShutterOrderItemCombinations[id].globalDriveType ? this.item.externalShutterOrderItemCombinations[id].globalDriveType : this.itemDriveTypes(id)[0]
        // this.item.externalShutterOrderItemCombinations[id].globalDriveSeries = this.item.externalShutterOrderItemCombinations[id].globalDriveSeries ? this.item.externalShutterOrderItemCombinations[id].globalDriveSeries : this.itemDriveSeries(id)[0]
        // this.item.externalShutterOrderItemCombinations[id].globalDrive = this.item.externalShutterOrderItemCombinations[id].globalDrive ? this.item.externalShutterOrderItemCombinations[id].globalDrive : this.itemGlobalDrives(id)[0]

      }

      //if(typeHeight === 'runners')await this.onExternalShutterCombinationRunnersHeightChange(id)
      //else if(typeHeight === 'full')await this.onExternalShutterCombinationFullHeightChange(id)

      //if(this.item.externalShutterOrderItemCombinationsCount > 1)await this.cloneHeightToDoubleCombination(id)

      //this.onExternalShutterCombinationWidthChange(id)
      //this.onExternalShutterCombinationFullHeightChange(id)
      //await this.onGlobalPipeChange(id)
      //await this.isCombinationWidthValid(id)
      //this.isFullHeightValid(id)


      this.calculatePrice();

    },

    checkGlobalPipe(id) {
      if (this.itemGlobalPipes(id).findIndex(pipe => pipe.id === this.item.externalShutterOrderItemCombinations[id].globalPipe.id) < 0) {
        this.item.externalShutterOrderItemCombinations[id].globalPipe = this.itemGlobalPipes(0)[0] || null
        this.calculatePrice();
      }
    },

    setMessageFromAccessory(message) {
      if (message && message.length > 0) {
        this.setMessage(message);
        this.setIsMessageBorderAnimating(true);
        setTimeout(() => {
          this.setIsMessageBorderAnimating(false);
        }, 1000);
      }
    },
    getPictureFromNestedElement(src, nestedElement) {
      const item = src && src[nestedElement];
      const picture = item && item["picture"];

      return picture || null;
    },
    //zwraca prosty url
    getPictureUrl(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return "url(" + picture.contentUrl + ")";
      }

      return null;
    },
    //zwraca obiekt stylu z tłem i background-size: contain
    getBackgroundPictureWithContain(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ") 0% 0% / contain"
        };
      }
      return null;
    },
    //zwraca obiekt stylu z tłem
    getBackgroundPicture(src, nestedField = null) {
      let picture = null;

      if (nestedField) {
        picture = this.getPictureFromNestedElement(src, nestedField);
      } else {
        picture = src && src["picture"];
      }

      if (picture) {
        return {
          background: "url(" + picture.contentUrl + ")"
        };
      }

      return null;
    },
    openImage(image, text = null) {
      this.imageForModal = image;
      if (text) {
        this.centeredTextForModal = text;
      } else {
        this.centeredTextForModal = null;
      }
      this.imageModal = true;
    },
    asArray(object, field) {
      return (object && object[field]) || [];
    },
    onQuantityChange() {
      this.calculatePrice();
    },
    /*  onGlobalRemoteQuantityChange() {
        if (!this.globalOrderRemoteControlIsPosting) {
          this.globalOrderRemoteControlIsPosting = true;
          this.calculatePrice();
          this.globalOrderRemoteControlIsPosting = false;
        }
      },*/
    setWindowFrame(newWindowFrame) {
      this.windowFrame = newWindowFrame;
    },
    resetWindowFrame() {
      this.windowFrame = null;
    },
    calculatePrice: debounce(async function () {
      let calculatorPriceAvailable = true;
      this.isCalculated = false;

      // check available calculate price
      //for item
      if (!this.item.externalShutterArmorExternalShutterArmorColorEntry ||
          !this.item.externalShutterArmorFabricShadeColorEntry ||
          !this.item.externalShutterRunnerFabricShadeColorEntry) {
        calculatorPriceAvailable = false
      }
      // for combination item elements
      this.item.externalShutterOrderItemCombinations.forEach((item, index) => {

        if (!item.externalShutterBox ||
            !item.externalShutterDrillHoleLeft || !item.externalShutterDrillHoleRight ||
            !item.externalShutterDriveExit || !item.externalShutterExternalShutterRunnerEntryLeft ||
            !item.externalShutterExternalShutterRunnerEntryRight ||
            Object.keys(item.externalShutterBox).length === 0) {
          calculatorPriceAvailable = false;
        } else if (
            !this.isFullHeightValid(index) || !this.isRunnersHeightValid(index) || !this.isCombinationWidthValid(index) || !item.externalShutterBox || !item.globalPipe
        ) {
          calculatorPriceAvailable = false
        } else if (
            item.globalDrive &&
            item.globalDrive.externalShutterGlobalDriveEntries[0] &&
            item.globalDrive.externalShutterGlobalDriveEntries[0].externalShutterProtection &&
            item.externalShutterExternalShutterProtectionEntries.findIndex(element => {
              return element.externalShutterProtection["@id"] === item.globalDrive.externalShutterGlobalDriveEntries[0].externalShutterProtection["@id"]
            }) === -1) {
          calculatorPriceAvailable = false
          this.addError(
              "externalShutterExternalShutterProtectionEntries",
              this.$t("default_protection_entry_required", {
                protectionEntry: item.globalDrive.externalShutterGlobalDriveEntries[0].externalShutterProtection["name"],
              }), index
          );
        }
      })

      if (!calculatorPriceAvailable) {
        return null;
      } else {
        this.priceLoading = true;
        let item = simpleClone(this.item);
        item = prepareItemToSent(item);
        delete item["@id"];
        delete item.id;
        await http
            .post(`/calculate/external_shutter_order_item`, item)
            .then(({data}) => {
              this.item.netPurchaseValue = data.netPurchaseValue;
              this.item.netSalesValue = data.netSalesValue;
              this.item.weight = data.weight;
              this.item.sumSurfacesAllCombinations = data.sumSurfacesAllCombinations;
              this.isCalculated = true;
              this.removeError(null, null, true)
            })
            .catch(error => {
              this.isCalculated = false;
              notify(error.response.data["hydra:description"], "danger");
            });
        this.priceLoading = false;
      }
    }, 400),
    addError(field, message, combinationId) {
      let existsError = this.errors.find(
          ({propertyPath, combination}) => (propertyPath === field && combination === combinationId)
      );
      if (existsError) {
        existsError.message = message;
      } else {
        this.errors = this.errors.concat({propertyPath: field, message, combination: combinationId});
      }
    },

    removeError(field, combinationId, all = false) {
      this.errors = this.errors.filter(
          ({propertyPath, combination}) => (field !== propertyPath || combination !== combinationId)
      );

      if (all) this.errors = []
    },

    addWarning(field, message, combinationId) {
      let existWarning = this.warnings.find(
          ({propertyPath, combination}) => (propertyPath === field && combination === combinationId)
      );
      if (existWarning) {
        existWarning.message = message;
      } else {
        this.warnings = this.warnings.concat({propertyPath: field, message, combination: combinationId});
      }
    },

    removeWarning(field, combinationId, all = false) {
      this.warnings = this.warnings.filter(
          ({propertyPath, combination}) => (field !== propertyPath || combination !== combinationId)
      );

      if (all) this.warnings = []
    },

    errorsByCombination(combinationId) {
      let errorsTemp = this.errors.filter(({combination}) => combination === combinationId)
      if (errorsTemp.length == 0) return []
      return errorsTemp

    },

    errorByPath(combinationId, path) {
      const errorsByCombination = this.errorsByCombination(combinationId)
      let errorsTemp = errorsByCombination.filter(item => item.propertyPath === path)
      if (errorsTemp.length == 0) return []
      return errorsTemp[0].message

    },
    warningsByCombination(combinationId) {
      let warningsTemp = this.warnings.filter(({combination}) => combination === combinationId)
      if (warningsTemp.length == 0) return []
      return warningsTemp

    },

    isCombinationWidthValid(id) {
      this.removeError("externalShutterWidth", id);

      if (this.item.externalShutterOrderItemCombinations[id].width > this.itemExternalShutterArmorMaxWidth) {
        this.addError(
            "externalShutterWidth",
            this.$t("max_width_error", {
              width: this.formatLength(this.maxWidth),
              lengthUnit: this.lengthUnit
            }), id
        );
        return false;
      }

      if (this.item.externalShutterOrderItemCombinations[id].width < this.itemExternalShutterMinWidth) {
        this.addError(
            "externalShutterWidth",
            this.$t("min_width_error", {
              width: this.formatLength(this.minWidth),
              lengthUnit: this.lengthUnit
            }),
            id
        );
        return false;
      }

      return true;
    },

    isRunnersHeightValid(id) {
      this.removeError("runnersHeight", id);
      if (this.item.externalShutterOrderItemCombinations[id].runnersHeight < this.minValue) {
        this.addError(
            "runnersHeight",
            this.$t("min_runners_height_error", {
              width: this.formatLength(this.minValue),
              lengthUnit: this.lengthUnit
            }), id
        );
        return false;
      }

      return true;
    },

    isFullHeightValid(id) {
      this.removeError("fullHeight", id);
      if (this.item.externalShutterOrderItemCombinations[id].fullHeight < this.minValue) {
        this.addError(
            "fullHeight",
            this.$t("min_full_height_error", {
              width: this.formatLength(this.minValue),
              lengthUnit: this.lengthUnit
            }), id
        );
        return false;
      }

      return true;
    },
    /**
     * zwraca arrayke obiektow zdjec kombinacji rolet zewnetrznych. zwraca wybrana strone z zadeklarowanej ilosci stron
     * @param page
     * @param maxPages
     * @returns array
     */
    getExternalShutterGraphics(page, maxPages) {
      if (page < 1 || maxPages < 1) {
        return this.externalShutterCombinationsGraphics;
      }
      if (page > maxPages) {
        return this.externalShutterCombinationsGraphics;
      }
      if (maxPages > this.externalShutterCombinationsGraphics.length) {
        return this.externalShutterCombinationsGraphics;
      }

      let count = Math.floor(this.externalShutterCombinationsGraphics.length / maxPages);
      let addition = this.externalShutterCombinationsGraphics.length - (maxPages * count);
      const graphics = [];
      for (
          let i = count * (page - 1);
          i < page * count + (maxPages === page ? addition : 0);
          i++
      ) {
        graphics.push(this.externalShutterCombinationsGraphics[i]);
      }

      return graphics;
    },
    onCombinationChanged(file) {
      if (this.item.externalShutterOrderItemCombinationsCount > file.itemsCount) {
        this.item.externalShutterOrderItemCombinationsCount = file.itemsCount;
        this.decreaseCombinationsInItem(file.itemsCount);
        this.setItemDefaults()
        this.combinationsSelectModal = false;
        this.item.selectedCombination = file.text;
        this.setCombinationImage(file.text)
      } else if (file.itemsCount > 1 && file.itemsCount <= 3) {
        this.item.externalShutterOrderItemCombinationsCount = file.itemsCount;
        this.increaseCombinationsInItem(file.itemsCount);
        this.setItemDefaults()
        this.combinationsSelectModal = false;
        this.item.selectedCombination = file.text;
        this.setCombinationImage(file.text)
      }

      this.cloneCombinationValueByCombinationType()
    },
    /**
     * Zmieniono napęd, więc sprawdz domyśle zabezpieczenie napędu, kóre jest nieusuwalne
     *
     */
    onGlobalDriveChanged(shouldCalculateAndChangeMessage = true, id) {
      //this.item.globalDriveControl =
      //(this.item.globalDrive &&
      //this.item.globalDrive.globalDriveControls[0]) ||
      //null;
//      this.item.globalRemoteControl = this.itemGlobalRemoteControls[0] || null;
      //    this.item.globalRemoteControlQuantity = 0;

      const externalShutterGlobalDriveEntryExternalShutterProtection =
          this.item.externalShutterOrderItemCombinations[id].globalDrive.externalShutterGlobalDriveEntries[0].externalShutterProtection

      const externalShutterExternalShutterProtectionEntry = this.itemExternalShutterExternalShutterProtectionsEntries(id).filter((item) => {
        if (!externalShutterGlobalDriveEntryExternalShutterProtection || !item.externalShutterProtection) return false
        return item.externalShutterProtection["@id"] === externalShutterGlobalDriveEntryExternalShutterProtection["@id"]
      })[0] || []

      if (
          externalShutterExternalShutterProtectionEntry &&
          externalShutterExternalShutterProtectionEntry.externalShutterProtection &&
          this.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterProtectionEntries.findIndex(element => {
            return element["@id"] === externalShutterExternalShutterProtectionEntry["@id"]
          }) === -1) {
        this.item.externalShutterOrderItemCombinations[id].externalShutterExternalShutterProtectionEntries.push(
            externalShutterExternalShutterProtectionEntry)
      }

      if (shouldCalculateAndChangeMessage) {
        this.setMessageFromAccessory((this.item.externalShutterOrderItemCombinations[id].globalDrive && this.item.externalShutterOrderItemCombinations[id].globalDrive.message) ? this.item.externalShutterOrderItemCombinations[id].globalDrive.message : null);
        this.calculatePrice();
      }
    },


    /**
     * Zmieniono napęd, więc zresetuj sterowania
     */
    onGlobalDriveTypeChanged(id) {
      this.item.externalShutterOrderItemCombinations[id].globalDriveSeries = simpleClone(this.itemDriveSeries(id)[0])

      this.setMessageFromAccessory(this.item.externalShutterOrderItemCombinations[id].globalDriveType.message ? this.item.externalShutterOrderItemCombinations[id].globalDriveType.message : null);
      this.onGlobalDriveSeriesChanged(id);
    },
    onGlobalDriveSeriesChanged(id) {
      this.item.externalShutterOrderItemCombinations[id].globalDrive =
          this.itemGlobalDrives(id).sort((first, next) => {
            if (first.liftingCapacityNm - next.liftingCapacityNm < 0) {
              return -1;
            }
            if (first.liftingCapacityNm - next.liftingCapacityNm > 0) {
              return 1;
            }
            if (first.pieceSurcharge - next.pieceSurchare < 0) {
              return -1;
            }
            if (first.pieceSurcharge - next.pieceSurchare > 0) {
              return 1;
            }
            return 0;
          })[0] || null;

      if (this.item.globalDrive) {
        // this.item.externalShutterOrderItemCombinations[id].globalDriveControl = this.item.globalDrive.globalDriveControls[0] || null;
      }
      this.onGlobalDriveChanged(true, id);
    },
    onGlobalDriveControlChanged(id) {
      this.setMessageFromAccessory(this.item.externalShutterOrderItemCombinations[id].globalDriveControl.message);

      this.item.externalShutterOrderItemCombinations[id].globalRemoteControl = this.itemGlobalRemoteControls[0] || null;
      this.item.globalRemoteControlQuantity = 0;
      this.calculatePrice();
    },
    onGlobalRemoteControlChanged() {
      this.setMessageFromAccessory(this.item.globalRemoteControl.message);
      this.calculatePrice();
    },

    /**
     * Zmieniono zabezpieczenie więc odśwież cenę
     *
     */
    onExternalShutterExternalShutterProtectionsEntriesChange() {
      this.calculatePrice()
    }
  },
  watch:{
    isEdit(){
      this.calculatePrice()
    }
  },
  /**
   * Computed properties
   */
  computed: {
    ...mapGetters([
      "locales",
      "externalShutterCombinationsGraphics",
      "hideImage",
      "hideWholeSalePrice"
    ]),
    ...mapGetters("externalShutters", [
      "order",
      "showForm",
      "image",
      "item",
      "isEdit",
      "combinationImage",
      "message",
      "isMessageBorderAnimating",
      "externalShutters",
      "itemHasMosquitoNet",
      "itemExternalShutterFullWidth",
      "itemExternalShutterMinWidth",
      "itemExternalShutterFullHeight",
      "itemExternalShutterPriceLists",
      "itemExternalShutterArmorMaxWidth",
      "itemExternalShutterArmorExternalShutterArmorColorEntries",
      "itemExternalShutterArmorFabricShadeColorEntries",
      "itemExternalShutterExternalShutterRunnerEntries",
      "itemExternalShutterRunnerDrillHolesLeft",
      "itemExternalShutterRunnerDrillHolesRight",
      "itemExternalShutterAdaptations",
      "itemGlobalPipes",
      "itemDriveTypes",
      "itemDriveSeries",
      "itemGlobalDrives",
      "itemGlobalDriveControls",
      "globalDriveType",
      "globalDriveSeries",
      "itemGlobalRemoteControls",
      "itemExternalShutterExternalShutterProtectionsEntries",
      "itemExternalShutterBoxes",
      "itemExternalShutterBoxToAutocomplete",
      "itemExternalShutterBoxInternalFabricShadeColorEntries",
      "itemExternalShutterBoxExternalFabricShadeColorEntries",
      "isSimpleForm",
      "itemExternalShutterDriveExits",
      "itemExternalShutterRunnerFabricShadeColorEntries"
    ]),
    ...mapGetters("company", ["lengthUnit", "companyInstallationValues"]),
    ...mapGetters("common", ["formatLength", "formatPrice", "formatWeight", "formatSurface",]),
    ...mapGetters("auth", ["hasPermission"]),
    orderHasAtLeastOneItem() {
      return this.order.externalShutterOrderItems.length > 0
          || this.order.automationOrderItems.length > 0;
    },
    companyInstallationValuesBySelectedExternalShutter() {
      if (!this.item.externalShutter) {
        return [];
      }

      const installationArray = objectToCollection(
          this.companyInstallationValues
      );

      return installationArray.filter(
          civ =>
              civ.externalShutters &&
              civ.externalShutters.findIndex(
                  fs => fs === this.item.externalShutter["@id"]
              ) > -1
      );
    },
    windowFrameBackgroundImage() {
      if (this.windowFrame && this.windowFrame.picture) {
        return {
          backgroundImage: "url(" + this.windowFrame.picture.contentUrl + ")"
        };
      }
      return {
        backgroundColor: "white"
      };
    },
    loading() {
      return this.$store.getters["common/loading"];
    },
    itemDataTitle() {
      if (this.isEdit) {
        return "item_edit_data_title";
      }
      return "item_data_title";
    },
    completeButtonLabel() {
      if (this.isEdit) {
        return "save_complete";
      }
      return "add_complete";
    },
    addButtonLabel() {
      if (this.isEdit) {
        return "save";
      }
      return "add";
    },
    getLocale() {
      const local = this.$i18n.locale.toUpperCase();
      if (this.locales.find(locale => local === locale)) {
        return local;
      }
      return "EN";
    },
    getHideImage() {
      return this.hideImage
    },
    externalShutterDriveExitHelp() {
      if (this.item.externalShutterDriveExit === "L+P") {
        return this.$t("l_plus_p_warning");
      }
      return "";
    },
    cantSave() {
      if (!this.isCalculated) {
        return true;
      } else if (this.item.width === 0 || this.item.height === 0) {
        return true;
      } else {
        let anyEmptyDriveInCombination = false
        for (let index in this.item.externalShutterOrderItemCombinations) {
          if (!this.item.externalShutterOrderItemCombinations[index].globalDriveType ||
              !this.item.externalShutterOrderItemCombinations[index].globalDriveSeries) anyEmptyDriveInCombination = true
        }
        return anyEmptyDriveInCombination
      }
    },
    combinationWidthHelp() {
      const showMax = true

      let message = `min: ${this.formatLength(this.minWidth)} ${
          this.lengthUnit
      }`;

      message += showMax
          ? `, max: ${this.formatLength(this.maxWidth)} ${this.lengthUnit}`
          : ``;

      return message;
    },

    minWidth() {
      return this.itemExternalShutterMinWidth;
    },
    maxWidth() {
      return this.itemExternalShutterArmorMaxWidth;
    },

    runnersHeightHelp() {

      let message = `min: ${this.formatLength(this.minValue)} ${
          this.lengthUnit
      }`;

      return message;
    },

    fullHeightHelp() {

      let message = `min: ${this.formatLength(this.minValue)} ${
          this.lengthUnit
      }`;

      return message;
    },

  }
};
</script>
